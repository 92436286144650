import React, { useEffect, useState } from 'react'
import { GetTradeApplicationAcceptancePromise, TradeApplicationChangeStatus, GeneratePartyDesicionProtocol, GetPartyDesicionProtocol, FinishPartyDesicion } from "../remote";
import { useDispatch, useSelector } from 'react-redux'
import { setApplication, setLoading, setLoadingFinish, setConfirm } from "../applicationSlice";
import { ITradeApplication, ApplicationStatusses, IAcceptanceModel, IAppChangeStatusModel } from '../types';
import { DATE_TIME_FORMAT } from "../../settings";
import moment from "moment";
import $ from "jquery";
import AddDeleteTableRows from "../../Common/Table/Table";
import GenerateAndSign from "../../Common/Document/GenerateAndSign"
import { Checkbox } from "../../Common/Form";
import CheckBoxList from "../../Common/Form/CheckboxList";
import TextArea from "../../Common/Form/TextArea";
import {
    ISelectListItem,
    IDocumentModel
} from "../../Common/types";

interface IPropsType {
    lotId: Number
}

export function ApplicationAcceptance({ lotId }: IPropsType) {

    const [firstTime, setFirstTime] = useState(true);
    const [model, setModel] = useState<IAcceptanceModel>(null);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [protocol, setProtocol] = useState<IDocumentModel>(null)
    const [thumbprint, setThumbprint] = useState<string>();
    const [selectedApp, setSelectedApp] = useState<ITradeApplication>(null);
    const availableOptions: Array<ISelectListItem> = [{ Text: "Принять", Value: ApplicationStatusses.Approved.toString(), Selected: true, Disabled: false },
        { Text: "Отклонить", Value: ApplicationStatusses.Declined.toString(), Selected: false, Disabled: false }];

    const [acceptState, setAcceptState] = useState(null);
    const [acceptMessage, setAcceptMessage] = useState(null);

    useEffect(() => {
        if (firstTime) {
            GetTradeApplicationAcceptancePromise(lotId).then((result) => {
                if (result.Success) {
                    setModel(result.UpdatedItem);
                    setProtocol(result.UpdatedItem.Protocol);
                    setThumbprint(result.UpdatedItem.Thumbprint)
                }
            });
        }

        setFirstTime(false);
    }, []);

    const setAppStatus = () => {
        console.log(acceptState);
        console.log(acceptMessage);
        setIsLoading(true);

        let model: IAppChangeStatusModel = { Id: selectedApp.Id, Reason: acceptMessage, Status: acceptState };

        TradeApplicationChangeStatus(model).then((result) => {
            if (result.Success) {
                setModel(result.UpdatedItem);
                setProtocol(result.UpdatedItem.Protocol);
                setThumbprint(result.UpdatedItem.Thumbprint)
                showHidePopup(null, 'hide');
            }
            else {
                setError(result.Error);
            }

            setIsLoading(false);
        });
    }

    const showHidePopup = (app, action) => {
        setAcceptState(null);
        setAcceptMessage(null);
        setSelectedApp(app);
        $('#app-details-modal').modal(action);
    }

    const onGenerateProtocol = (callback) => {
        GeneratePartyDesicionProtocol(lotId).then((result) => {
            if (result.Success) {
                setProtocol(result.UpdatedItem);
                callback(null)
            }
            else {
                callback(result.Error)
            }
        });
    }

    const onSignProtocol = (callback) => {
        FinishPartyDesicion(lotId).then((result) => {
            window.location.reload();
        });
    }

	return (
        <div className="trade-acceptance-lot">

            {model && model.Applications && model.Applications.length > 0 ?
                <div className="apps-list">
                    <table className="beting-list">
                        <thead>
                            <tr>
                                <th style={{ width: '30%' }}>Участник</th>
                                <th style={{ width: '20%' }}>Дата</th>
                                {model.ShowBets &&
                                    <th style={{ width: '20%' }}>Ставка</th>
                                }
                                <th style={{ width: '30%' }}>Статус</th>
                            </tr>
                        </thead>
                        <tbody className="beting-list-items">
                            {model.Applications.map(app =>
                                <tr>
                                    
                                    <td><a role="button" onClick={(e) => { showHidePopup(app, 'show') }} title={app.ParticipantName}>
                                        {app.ParticipantName}
                                    </a></td>
                                    <td className="hidden-mobile">{moment(app.ApplyDate).format('DD.MM.YYYY hh.mm.ss')}</td>
                                    {model.ShowBets &&
                                        <td>{app.Bet}</td>
                                    }
                                    <td>
                                        <span>{app.StatusName}</span>
                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </table>
                </div> :
                <div className="no-apps">Заявок нет</div>
            }

            {model && model.AllowProtocolGeneration && 

                <div className="protocol-container">
                    <GenerateAndSign Doc={protocol} onGenerate={onGenerateProtocol} onSigned={onSignProtocol} Thumbprint={thumbprint}></GenerateAndSign>
                </div>
            }

            <div className="modal fade app-modal" id="app-details-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-width-1064 modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <button type="button" className="modal-close-btn" data-dismiss="modal" aria-label="Close" onClick={(e) => { e.preventDefault(); showHidePopup(null, 'hide'); }}>
                            <i className="m-icon-timer"></i>
                        </button>
                        <div className="modal-body text-center">

                            {selectedApp && selectedApp.Id != 0 &&
                                <React.Fragment>
                                    <h4>Заявка от {selectedApp.ParticipantName}</h4>

                                    <p className="paragraph size-14 size-sm-12 opacity-1 mb-3 pb-3">
                                        Добавлено: {moment(selectedApp.ApplyDate).format(DATE_TIME_FORMAT)}
                                        <br />
                                        Статус: {selectedApp.StatusName}
                                    </p>


                                    <AddDeleteTableRows
                                        idValue={selectedApp.Id}
                                        idProperty="TradeApplicationId"
                                        data={selectedApp.Documents}
                                        prefix="fl"
                                        onAddDocument={() => { }}
                                        isEditable={false}
                                        onDocSigned={(id, error) => { }}
                                    />

                                    {(selectedApp.StatusId == ApplicationStatusses.WaitingForConfirmation) &&
                                        <React.Fragment>
                                            <CheckBoxList
                                                allowMultiple={false}
                                                checkBoxAdditionalClass={"mb-2"}
                                                items={availableOptions}
                                                name={"TradeApplicationStatusChange"}
                                                disabled={false}
                                                onChange={(s, add) => {
                                                    if (add) {
                                                        let state = parseInt(s.Value);

                                                        if (state == ApplicationStatusses.Approved)
                                                            setAcceptMessage('');

                                                        setAcceptState(parseInt(s.Value));
                                                    }
                                                }}
                                                showValidation={false}
                                                rowCount={1}
                                                selectedItems={[acceptState]}
                                            />

                                            {acceptState == ApplicationStatusses.Declined && 

                                            <TextArea
                                                label={"Причина отказа"}
                                                name={"DeclineReason"}
                                                value={acceptMessage}
                                                onChange={(e) => { }}
                                                required={acceptState == ApplicationStatusses.Declined}
                                                placeholder="Введите текст"
                                                removeValidationMsg={true}
                                                errorMessage={""}
                                                columnClass={"col-md-7 gutter-col-10"}
                                                onBlur={(e) => {
                                                    setAcceptMessage(e.target.value);
                                                }}
                                            />
                                            }

                                            {error && <p className={"always-block invalid-feedback mb-0 pt-2"}>{error}</p>}
                                            <button className="btn btn-primary btn-sm-mobile btn-font-14 btn-lg px-2" onClick={(e) => { setAppStatus(); }}>
                                                {isLoading ? (
                                                    <img
                                                        src="/Content/images/loading_sm.gif"
                                                        style={{ width: "20px" }}
                                                    />
                                                ) : "Рассмотреть Заявку"}
                                            </button>
                                        </React.Fragment>}

                                </React.Fragment>
                            }


                        </div>

                    </div>
                </div>
            </div>
            
		</div>
	);
}