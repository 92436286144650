import React, { useState, useEffect, ChangeEventHandler, FocusEventHandler } from "react";
import { Nullable } from "../types";
import $ from "jquery";
import moment from "moment";

interface IPropsType {
    label?: string,
    name: string,
    value: Nullable<Date>,
    removeValidationMsg?: boolean,
    onValueChanged?: (value: any) => void,
    tooltipText?: string,
    errorMessage?: string,
    format?: string,
    placeholder?: string,
    wrapInsideContainer?: boolean,
    additionalContainerClass?: string,
    disabled: boolean
}

function dateTimeInput(props: IPropsType) {

    const [selectedValue, setSelectedValue] = useState(props.value);
    const [firstLoad, setFirstLoad] = useState(true);
    const stdInputClass = (!props.removeValidationMsg && props.errorMessage ? " is-invalid" : "") + " form-control app-form-control";
    const stdContainerClass = "input-custom input-custom-datepicker date-picker-only input-group date " + (props.additionalContainerClass ? props.additionalContainerClass : "");

    useEffect(() => {

        if (firstLoad) {
            $('#' + props.name).datetimepicker({
                defaultDate: new Date(),
                sideBySide: true,
                format: props.format,
                //locale: moment.locale('ru'),
            }).on('dp.change', function (e) {
                setSelectedValue(e.date); $(this).datetimepicker('hide');
            });;
            setFirstLoad(false);
        }
        else {
            
        }
    }, [props.name]);

    useEffect(() => {
        if (!firstLoad) {
            props.onValueChanged(moment(selectedValue).format(props.format));
        }
    }, [selectedValue]);

    return (
        
        <React.Fragment>
            {props.label && <h6 className="field-label-16-roboto pt-0">{props.label} </h6>}
            <div className={stdContainerClass} id={props.name} style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                <input type="text" className={stdInputClass} value={props.value ? moment(props.value, props.format).format(props.format) : ''} placeholder={props.placeholder} required />

                <button className="input-group-addon btn">
                    <span className="m-icon-calender calender-btn" ></span>
                </button>
            </div>
            {!props.removeValidationMsg && <p className="always-block invalid-feedback mb-0 pt-2">{props.errorMessage}</p>}
        </React.Fragment>
        

        //<div className="form-group">
        //    <label {...(props.tooltipText ? { "data-toggle": "tooltip", title: props.tooltipText } : {})}>
        //        {props.label} {props.required && <span className="requirement">(*)</span>}:
        //    </label>
        //    <Field
        //        name={props.name}
        //        type="hidden" />
        //    <DateTimePicker value={props.value ? new Date(props.value) : null}
        //        disableClock={true}
        //        format={DATE_TIME_FORMAT}
        //        onChange={handleDateChange} />

        //    {!props.removeValidationMsg && <ErrorMessage name={props.name} component="span" className="field-validation-error"/>}
        //</div>
    );

}

export default dateTimeInput;