/* eslint-disable prettier/prettier */
import { DocumentTypes, OrderStatus } from "../Common/types";
import Fetcher from "../Common/Fetch";

class AccountInfo  {
    constructor() { }

    Id: 0;
    Fio: "";
    IsAdminRole: false;
    Thumbprint: "";
    CurrentUserCanSign: false;
    ListDocuments: [{ Id: 0, DocumentType: DocumentTypes, IsSigned: false, DataToSign: string, Signature: string }];
    PaymentLots: [{ AccountId: 0, LotId: 0, StatusId: 0 }];
    Services: [{}];
    isSignedDocumentContract: false;

    init(callback) {

        this.ListDocuments = [{}];
        this.Services = [{}];

        if (this.Id > 0)
            Fetcher('/ProfileApi/GetAccountInfo/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    this.PaymentLots = data.payments;

                    if (data.documentContract) {
                        this.ListDocuments.push(data.documentContract);
                    }
                    this.Services = data.services;

                    this.isSignedDocumentContract = this.isDocumentSigned(DocumentTypes.Contract);

                    callback(true);
                })
                .catch((exception) => {
                    console.log('GetAccountInfo catch: ', exception);
                    callback(true);
                });
    }

    isTariffBisness() {
        if (this.Services && this.Services.length > 0) {
            if (this.Services[0].OrderStatusId == OrderStatus.Paid)
                return true;
            else return false;
        }
    }

    isDocumentSigned(type: DocumentTypes) {
        if (this.ListDocuments && this.ListDocuments.length > 0) {
            let len = this.ListDocuments.length;

            for (var i = 0; i < len; i++) {
                if (this.ListDocuments[i].DocumentType == DocumentTypes.Contract)
                    if (this.ListDocuments[i].IsSigned)
                        return true;
            }
        }
        return false;

    }

}
export default new AccountInfo();