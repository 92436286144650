import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { useSignal, useSlot } from "react-signal-slot";
import { Button } from "primereact/button";
import { Profile, StatusCheckingDescription, StatusChecking, IdocumentModel } from "./types";
import ConfirmPopup from "../Common/ConfirmPopup/ConfirmPopup";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";


function UserDetailsTable(props) {

    const [users, setUsers] = useState<Array<Profile>>([]);

    const [expandedRows, setExpandedRows] = useState({});

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [editedUser, setEditedUser] = useState<Profile>(null);

    const sendNewStatus = useSignal();

    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [popupText, setPopupText] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [paggingSize, setPaggingSize] = useState(null);
    const [first, setFirst] = useState(0);

    const [filters, setFilters] = useState([]);

    const companyTypeName = {
        1: "Юридическое лицо",
        2: "Индивидуальный предприниматель",
        3: "Физическое лицо"
    };

    const SignalGetData = useSignal();

    // При первом рендере отправляет сигнал для получения массива пользователей
    // И показывает попап-уведомление об этом
    useEffect(() => {
        setPopupText('Загрузка данных')
        setIsOpenPopup(true);
        setTimeout(() => {
            SignalGetData('SignalGetData',
                {
                    page: 1,
                    countRecord: 5,
                    SortField: '',
                    Filters: [{ FieldName: '', Value: '', Condition: '' }]
                }
            )
        }, 1000)
    }, [])

    // Ожидание получения данных от SignalGetData 
    useSlot('SignalGetDataResult', (data) => {
        console.log('Всего пользователей:' + data.totalRecords);
        console.log(data.UpdatedItem);
        if (data.Success) {
            setPaggingSize(data.totalRecords);
            console.log('Страниц в пагинации:' + Math.ceil(data.totalRecords / 5));
            setIsOpenPopup(false);
            setUsers(data.UpdatedItem.map(user => ({
                ...user,
                isEdited: false,
                RegisterDate: new Date(user.RegisterDate).toLocaleDateString('ru-RU'),
                CompanyTypeId: companyTypeName[user.CompanyTypeId],
                FirstName: user.LastName + ' ' + user.FirstName.split('')[0] + ' ' + user.MiddleName.split('')[0],
                StatusCheckingName: StatusCheckingDescription[user.StatusChecking]
            })));
        }
    })

    
    // Модель данных которая рендерится в каждой строке пользователя(Открывающийся список)
    const rowExpansionTemplate = (user:Profile) => {
        return (
            <div className="userDetails">
                <div className="details">
                    <h4>Персональные данные</h4>
                    <p>Фамилия: {user.LastName}</p>
                    <p>Имя: {user.FirstName}</p>
                    <p>Отчество: {user.MiddleName}</p>
                    <p>Дата рождения: {user.Birthday}</p>
                    <p>Серия и номер паспорта: {user.PassportSeries} / {user.PassportNumber}</p>
                    <p>Дата выдачи: {new Date(user.PassportDate).toLocaleDateString('ru-RU')}</p>
                    <p>Кем выдан документ: {user.PassportPlace}</p>
                    <p>Адрес места жительства: {user.RegAddress}</p>
                    <p>Адрес проживания: {user.FactAddress}</p>
                </div>
                <div className="bankDetails">
                    <h4>Банковские реквизиты</h4>
                    <p>Наименование банка: {user.BankRequisite.BankName}</p>
                    <p>БИК: {user.BankRequisite.Bic}</p>
                    <p>Расчетный счет: {user.BankRequisite.PersonalAccount}</p>
                    <p>КПП: {user.BankRequisite.KPP}</p>
                    <p>Кор. счет: {user.BankRequisite.CorrespondentAccount}</p>
                </div>
                <table className="defaultTable">
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>Тип документа</th>
                            <th>Дата добавления</th>
                            <th>Статус подписи</th>
                            <th>Комментарий</th>
                            <th>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{user.Documents.Name}</td>
                            <td>{user.Documents.DocumentTypeDescription}</td>
                            <td>{user.Documents.DataToSign}</td>
                            <td>{user.Documents.SignStatusDescription}</td>
                            <td>{user.Documents.Comment}</td>
                            <td><a href={user.Documents.DocumentLink} download>Скачать</a></td>
                        </tr>
                    </tbody>
                </table>
                <div className="action">
                    {user.StatusChecking != StatusChecking.PersonalDataComfirmed &&
                        <>
                            <button className="defaultPinkButton"
                            onClick={() => {
                                setEditedUser(user);
                                setSelectedStatus(11);
                                handleChangeStatus();
                            }}
                            >
                                Подтвердить
                            </button>

                            <button className="defaultBlackBorderButton"
                            onClick={() => {
                                setEditedUser(user);
                                setSelectedStatus(3);
                                handleChangeStatus()
                            }}
                            >
                                Отклонить
                            </button>
                        </>
                    }

                    {user.StatusChecking == StatusChecking.PersonalDataComfirmed &&
                        <img src="../../../main-images/confirmIcon.jpg" />    
                    }
                </div>
            </div>
        );
    };

    //handleEdit
    // Обработчик нажатия на иконку редактирования
    const handleEdit = (user) => {
        setEditedUser(user);
        setUsers(users.map((us) => (
            us.AccountId === user.AccountId
                ? { ...us, isEdited: true }
                : { ...us, isEdited: false}
        )));
    }
    //handleEdit


    //handleDelete
    // Обработчик нажатия на иконку "Удалить пользователя"
    const signalOpenPopup = useSignal();
    const signalSetDescription = useSignal();
    const signalDeleteAccount = useSignal();

    // Устанавляет редактируемого пользователя в EditedUser
    // и открывает попап для подтверждения
    const handleDelete = (user) => {
        setEditedUser(user);
        signalSetDescription('setConfirmPopupDescription', 'Вы уверены что хотите удалить аккаунт?');
        signalOpenPopup('openConfirmPopup', true);
    }

    // Обработчик подтверждения из попапа
    // Если true - показывает попап об обработке запроса и отправляет сигнал
    useSlot('handleConfirmPopup', (bool) => {
        console.log(bool);
        if (bool) {
            signalOpenPopup('openConfirmPopup', false)
            setPopupText('Обработка запроса');
            setIsOpenPopup(true);

            signalDeleteAccount('deleteAccount', editedUser.AccountId);
        } else {
            signalOpenPopup('openConfirmPopup', false)
        }
    })

    // Когда пришел ответ из сигнала об удалении пользователя - удаляет его, либо же выводит ошибку
    useSlot('signalResultDeleteData', (result) => {
        console.log(result)
        if (result.Success) {
            setUsers(users.filter(user => user.AccountId !== editedUser.AccountId));

            setPopupText('Пользователь удален');
        } else {
            setPopupText('Ошибка');
        }
    })
    //handleDelete


    //handleNotification
    const signalOpenNotifPopup = useSignal();
    const signalSetNotifDescription = useSignal();
    const signalSendNotif = useSignal();

    // Устанавляет редактируемого пользователя в EditedUser
    // и открывает попап для уведомления
    const handleNotif = (user) => {
        setEditedUser(user);
        signalSetNotifDescription('setNotifPopupDescription', 'Введите текст уведомления');
        signalOpenNotifPopup('openNotifPopup', true);
    }

    // Обработчик текста уведомления из попапа
    // и отправляет сигнал об отправке уведомления пользователю
    // передается accountId и сам текст
    useSlot('handleNotifPopup', (text) => {
        signalOpenNotifPopup('openNotifPopup', false)
        setPopupText('Обработка запроса');
        setIsOpenPopup(true);

        signalSendNotif('sendNotification', { AccountId: editedUser.AccountId, TextNotification: text });
    })

    // Когда пришел ответ из сигнала об отправке уведомления - показывает сообщение об
    // успешной отправке, либо же ошибку
    useSlot('signalResultSendNotification', (result) => {
        console.log(result)
        if (result.Success) {
            setPopupText('Уведомление отправлено');
        } else {
            setPopupText('Ошибка');
        }
    })
    //handleNotification

    // Модель для колонки действий
    const actionTemplate = (rowData) => {
        if (rowData.isEdited) {
            return (
                <div className="actionButtons">
                    <button className="defaultPinkButton">Ок</button>
                    <button className="defaultPinkButton"
                        onClick={() => {
                            setUsers(users.map((user) => (
                                user.AccountId === rowData.AccountId
                                    ? { ...user, isEdited: false }
                                    : user
                            )));
                        }}
                    >Отмена</button>
                </div>
            )
        } else {
            return (
                <div className="actionButtons">
                    <span title="Изменить статус" onClick={() => handleEdit(rowData)}>
                        <img src="../../../main-images/editIcon.png" />
                    </span>
                    <span title="Удалить" onClick={() => handleDelete(rowData)}>
                        <img src="../../../main-images/deleteIcon.png" />
                    </span>
                    <span title="Уведомление" onClick={() => handleNotif(rowData)}>
                        <img src="../../../main-images/notifIcon.png" />
                    </span>
                </div>
            );
        }
        
    };

    // ChangeStatus
    const signalOpenPopup2 = useSignal();
    const signalSetDescription2 = useSignal();
    const signalChangeStatus = useSignal();

    // При нажатии "Подтвердить" в открывающемся списке с данными о пользователе
    // Открывается попап подтверждения
    // Редактируемый пользователь установлен уже в событии onClick кнопки
    const handleChangeStatus = () => {
        signalSetDescription('setConfirmPopupDescription2', 'Подтвердите действие');
        signalOpenPopup2('openConfirmPopup2', true);
    }

    // Обработчик подтверждения из попапа
    // Если true - показывает попап об обработке запроса и отправляет сигнал об смене статуса
    // Передается accountId и selectedStatus(уже установлен в событии onClick кнопки)
    useSlot('handleConfirmPopup2', (bool) => {
        if (bool) {
            signalOpenPopup('openConfirmPopup2', false)
            setPopupText('Обработка запроса');
            setIsOpenPopup(true);

            sendNewStatus('ChangeStatusChecking', { AccountId: editedUser.AccountId, NewStatus: selectedStatus })
        } else {
            signalOpenPopup('openConfirmPopup2', false)
        }
    })

    // Когда пришел результат об смене статуса - изменяет значение в основном массиве users
    // и показывает соответствующее сообщение об успехе
    // либо же выводит ошибку
    useSlot("handleChangeStatus", (result) => {
        if (result.Success) {
            setUsers(users.map((user) => (
                user.AccountId === editedUser.AccountId
                    ? { ...user, isEdited: false, StatusCheckingName: StatusCheckingDescription[selectedStatus] }
                    : user
            )));

            setPopupText('Статус изменен');
        } else {
            setUsers(users.map((user) => (
                user.AccountId === editedUser.AccountId
                    ? { ...user, isEdited: false }
                    : user
            )));
            setPopupText('Ошибка');
        }
    })
    // ChangeStatus


    // Pagging
    // Обработчик пагинации
    const handlePageChange = (event) => {
        const newPage = event.page + 1;
        setFirst(event.first);
        setCurrentPage(newPage);
        SignalGetData('SignalGetData',
            {
                page: newPage,
                countRecord: 5,
                SortField: '',
                Filters: [{ FieldName: '', Value: '', Condition: '' }]
            }
        )
    };

    // Filters
    // Обработчик фильтров
    const handleFilters = () => {
        let filter = filters.filter(item => item.Value.trim());
        console.log(filter);
        setCurrentPage(1);
        setFirst(1);
        SignalGetData('SignalGetData',
            {
                page: 1,
                countRecord: 5,
                SortField: '',
                Filters: filter
            }
        )
    }

    // Pagging

    return (
        <>
            <div className="UserDetailsTable">
                <div className="tableFilters">
                    <div className="inputWrapper">
                        <span>ФИО</span>
                        <input className="defaultInput"
                            onChange={(e) => {
                                if (filters && filters.find(item => item.FieldName == 'FIO')) {
                                    setFilters(filters.map(item => 
                                        item.FieldName == 'FIO'
                                            ? { ...item, Value: e.target.value }
                                            : item
                                    ))
                                } else {
                                    setFilters([...filters, { FieldName: 'FIO', Value: e.target.value, Confition: 'like' }])
                                }
                            }}
                        />
                    </div>
                    <div className="inputWrapper">
                        <span>Email</span>
                        <input className="defaultInput"
                            onChange={(e) => {
                                if (filters && filters.find(item => item.FieldName == 'Email')) {
                                    setFilters(filters.map(item => 
                                        item.FieldName == 'Email'
                                            ? { ...item, Value: e.target.value }
                                            : item
                                    ))
                                } else {
                                    setFilters([...filters, { FieldName: 'Email', Value: e.target.value, Confition: 'like' }])
                                }
                            }}
                        />
                    </div>
                    <div className="inputWrapper">
                        <span>Статус</span>
                        <select className="customSelect"
                            onChange={(e) => {
                                if (filters && filters.find(item => item.FieldName == 'Status')) {
                                    setFilters(filters.map(item => 
                                        item.FieldName == 'Status'
                                            ? { ...item, Value: e.target.value }
                                            : item
                                    ))
                                } else {
                                    setFilters([...filters, { FieldName: 'Status', Value: e.target.value, Confition: 'equal' }])
                                }
                            }}
                        >
                            <option value={StatusChecking.RegistrationDeclined}>В регистрации отказано</option>
                            <option value={StatusChecking.WaitingConfirmPersonalData}>Ожидание подтверждения</option>
                            <option value={StatusChecking.PersonalDataComfirmed}>Подтвержден</option>
                        </select>
                    </div>
                    <button className="defaultPinkButton" onClick={handleFilters}>Применить</button>
                </div>
                <DataTable
                    value={users}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="AccountId"
                    className="defaultTable"
                    style={{ tableLayout: 'fixed', width: '100%' }}
                >
                    <Column expander style={{ width: '5rem' }} />

                    <Column style={{ width: '15%'}} field="FirstName" header="ФИО" sortable></Column>
                    <Column style={{ width: '15%' }} field="CompanyTypeId" header="Тип аккаунта" sortable></Column>
                    <Column style={{ width: '15%' }} field="RegisterDate" header="Дата регистрации" sortable></Column>
                    <Column style={{ width: '20%' }} field="Email" header="Email" sortable></Column>
                    <Column style={{ width: '25%' }} field="StatusCheckingName" header="Статус" sortable></Column>
                    <Column style={{ width: '10%' }} header="Действия" body={actionTemplate}></Column>
                </DataTable>
                <Paginator first={first} rows={5} totalRecords={paggingSize} onPageChange={handlePageChange} />
            </div>

            {isOpenPopup &&
                <div className="defaultPopupWrapper">
                    <div className="defaultPopup">
                        <button className="closePopupButton" onClick={() => { setIsOpenPopup(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                            </svg>
                        </button>

                        <div className="popupTitle">
                            <h4>
                                {popupText}
                                <hr></hr>
                            </h4>
                        </div>
                    </div>
                </div>   
            }

            <ConfirmPopup signalOpenPopup="openConfirmPopup" description="setConfirmPopupDescription" signalConfirm="handleConfirmPopup" />
            <NotificationPopup signalOpenPopup="openNotifPopup" description="setNotifPopupDescription" signalSendText="handleNotifPopup" />
            <ConfirmPopup signalOpenPopup="openConfirmPopup2" description="setConfirmPopupDescription2" signalConfirm="handleConfirmPopup2" />
        </>
    )
}

export default UserDetailsTable;