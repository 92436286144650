import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from "react";
import { useSignal, useSlot } from 'react-signal-slot'
import Fetcher from "../Common/Fetch";

interface IPropsType {
    text: string
}

function FilterSearchLots(props: IPropsType) {

    // свойство для тестового поля ввода текста для поиска лота по названию/описанию
    const [text, setText] = useState(props.text);

    const signalSearchLotByText = useSignal();

    const onClickButtonSearch = () => {
        console.log('onClick', text);
        signalSearchLotByText('SearchLotByText', text);
    }

    return (
        <>
        <div className="head">
            <div className="search-category">
                <div className="searchBar">
                    <input type="text" placeholder="Найти лот" value={text} onChange={(e) => setText(e.target.value)} />
                    <img src="../../../main-images/articlesSearchIcon.svg" />
                </div>
                <span onClick={onClickButtonSearch}>Поиск</span>
            </div>
         </div>
        </>
    );
}

export default FilterSearchLots;