import React, { useState, useRef, useId } from "react";
import Fetcher from "../Fetch";
declare var CADESCOMSignData: any;

/**
   @interface IPropsType - интерфейс предоставляющий некоторые поля необходимые компоненту Row для его работы. 
   @property signInfo - объект хранящий информацию и эцп пользователя
   @property rowsData  - массив документов. В данном массиве хранятся объекты документов. 
             Описание полей объекта можно увидеть в return главной функции. Данный объект есть ссылка из основного компонента Table.
             Так как нам необходимо и в основном компоненте иметь список документов и в компоненте Row. То мы проводим его из основного компонента сюда.
   @property setRowsData - ссылка на метод из основного компонента Table, обновляющего rowsData.
             Необходим нам в этом дочернем компоненте, чтобы вызвать обновление списка документов 
             и отрисовать его согласно прописанным инструкциям ниже.
   @property showInfoMessage - ссылка на метод из компонента Table. Метод показывает информационное сообщение.
   @property showErrorMessage - ссылка на метод из компонента Table. Метод показывает сообщение об ошибке.
   @property clearErrorMessage - ссылка на метод из компонента Table. Метод очищает поле сообщения об ошибке.
   @property getLotId - ссылка на метод из компонента Table. Метод возвращет поле LotId
*/
interface IPropsType {
    signInfo: {},
    rowsData: [],
    setRowsData: null,
    isEditable: boolean,
    showInfoMessage: ({ }) => void,
    showErrorMessage: ({ }) => void,
    clearErrorMessage: () => void,
    getIdValue: () => Number,
    getIdName: () => string,
    onDocSigned?: (id: number, error: string) => void
}


/**
   @function TableRows - функциональный компонет описывающий строку в таблице и содержащий логику работы с компонентами строки.
*/
function TableRows(props: IPropsType) {

/**
    @property стрелочная функция, выполняющая асинхронный запрос к серверу с данными в формате json
    @param url адрес на сервере куда должен уйти запрос
    @param data данные которые должны быть посланы. Могут быть null или в формате JSON 
    @param callback callback функция, которая будет вызвана при получении ответа от сервера для дальнейшей обработки ответа.
*/
    const fetcherPOSTJSON = (url, data, callback) => {
        Fetcher(url,
            {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    callback(result);
                }
            );
    }

    /**
    @property стрелочная функция, выполняющая асинхронный запрос к серверу с данными обернутыми в тег form
    @param url адрес на сервере куда должен уйти запрос
    @param data данные которые должны быть посланы. Должны быть типа FormData
    @param callback callback функция, которая будет вызвана при получении ответа от сервера для дальнейшей обработки ответа.
*/

    const fetcherPOST = (url, data, callback) => {
        Fetcher(url,
            {
                method: 'POST',
                body: data
            })
            .then(res => res.json())
            .then(
                (result) => {
                    callback(result);
                }
            )
            .catch((exception) => {
                console.log('catch: ', exception);
                callback(null);
            });
    }

    /**
       @property метод загружающий файл на сервер. Вызывается при клике по кнопке загрузить(кнопка со стрелкой вверх)
       @param index индекс элемента в массиве документов rowsData
       @param evnt событие
   */
    const sendFileToServer = (index, evnt) => {
        var rowsInput = [...props.rowsData];
        var item = rowsInput[index];
        var idValue = props.getIdValue();
        var idName = props.getIdName();

        props.clearErrorMessage();

        let isError = false;

        if (item.DocumentTitle.Value == '') {
            isError = true;
            props.showErrorMessage('Введите наименование документа');
        }

        if (item.DocumentFile.Value == null) {
            isError = true;
            props.showErrorMessage('Выберите документ');
        }

        if (!isError) {
            item.DocumentFile.Mode = 'Uploading';
            props.setRowsData(rowsInput);

            // выполним загрузку файла на сервер
            const formData = new FormData();
            formData.append("DocumentFile", item.DocumentFile.Value);
            formData.append("Comment", item.Comment.Value);
            formData.append("Title", item.DocumentTitle.Value);
            formData.append(idName, idValue.toString());

            fetcherPOST("/DocumentApi/AddDocument", formData, function (result) {
                if (result == null) {
                    props.showErrorMessage('Произошла ошибка. Попробуйте позже.');
                    item.DocumentFile.Mode = 'Edit';
                }
                else if (result.Success) {
                    console.log('success', result);
                    item.DocumentTitle.Mode = 'View';
                    item.Comment.Mode = 'View';
                    item.DocumentFile.Mode = 'View';
                    item.DocumentId = result.UpdatedItem.docId;
                    item.Signature = result.UpdatedItem.Signature;
                    item.DataToSign = result.UpdatedItem.DataToSign;
                    item.IsAutoSave = true;

                }
                else if (result.Error) {
                    item.DocumentFile.Mode = 'Edit';
                    props.showErrorMessage(result.Error);
                }

                props.setRowsData([...rowsInput]);
            });
        }
    };

    /**
   @property метод удаляющий строку из таблице и связанный с ней документ.
   @param index индекс элемента в массиве документов rowsData
*/

    const deleteTableRows = (index) => {
        var rows = props.rowsData;
        var item = rows[index];
        props.clearErrorMessage();

        if (item.DocumentId > 0) {
            var url = "/DocumentApi/RemoveDocument?id=" + item.DocumentId;
            fetcherPOSTJSON(url, null, function (result) {
                if (result.Success) {
                    rows.splice(index, 1);
                    props.setRowsData([...rows]);
                }
                else {
                    props.showErrorMessage('Произошла ошибка. Попробуйте позже.');
                }
            });
        }
        else {

            rows.splice(index, 1);
            props.setRowsData(rows);
        }
    };

    /**
@property предоставляюющий интерфейс для редактирования поля строки
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const editColumn = (index, nameField) => {
        var rowsInput = [...props.rowsData];
        var field = rowsInput[index][nameField];
        field.OldValue = field.Value;
        field.Mode = 'Edit';
        props.setRowsData(rowsInput);
    }

    /**
@property отвечающий за отмену изменений некоторого поля строки
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const cancelChanges = (index, nameField) => {
        var rowsInput = [...props.rowsData];
        var field = rowsInput[index][nameField];
        field.Value = field.OldValue;
        field.Mode = 'View';
        props.setRowsData(rowsInput);
    }

    /**
@property отвечающий за сохранение изменений некоторого поля строки. Отправляет новое значение на сервер для сохранения.
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const saveChanges = (index, nameField) => {
        console.log('saveChanges'); 
        var rowsInput = [...props.rowsData];
        var item = rowsInput[index];
        var field = item[nameField];
        console.log(rowsInput[index]);  

        var url = "/DocumentApi/EditDocument";
        var _data = { DoicId: item.DocumentId, Field: nameField, NewValue: field.Value };
        var _this = this;

        console.log(_data);
        console.log(JSON.stringify(_data));

        fetcherPOSTJSON(url, JSON.stringify(_data), function (result) {
            if (result.Success) {
                console.log('success');
                field.Mode = 'View';
                props.setRowsData(rowsInput);
                props.showInfoMessage('Изменения успешно сохранены');
            }
            else if (result.Error) {
                console.log('error: ', result.Error);
                props.showErrorMessage('Произошла ошибка. Попробуйте позже.');
            }
        });
    }

    /**
@property отвечающий за измнения в текстовом поле и проводящий эти изменения в модель. Вызвается по событию onChange
@param index индекс элемента в массиве документов rowsData
@param evnt событие
*/
    const handleChange = (index, evnt) => {
        let data = evnt.target.name.split('_');
        let name = data[0];

        const rowsInput = [...props.rowsData];
        rowsInput[index][name].Value = evnt.target.value;
        props.setRowsData(rowsInput);
    };

    /**
@property реагирующий на выбор файла с компьютера пользователем. И проводящий эти изменения в модель. Вызвается по событию onChange у <input type=field>
@param index индекс элемента в массиве документов rowsData
@param evnt событие
*/
    const handleFileChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const file = evnt.target.files?.[0];
        const rowsInput = [...props.rowsData];
        rowsInput[index].DocumentFileName = file.name;
        rowsInput[index].DocumentFile.Value = file;
        props.setRowsData(rowsInput);
    };

    /**
@property обрабатывающий нажатие на ссылку Подписать документ. 
Получает информацию о документе из скрытых полей и проводит её в CADESCOMSignData
который непосредственно подписывает документ эцп пользователя.
@param index индекс элемента в массиве документов rowsData
*/
    const handleSignProtocol = (index) => {
        const rowsInput = [...props.rowsData];
        var doc = rowsInput[index];

        CADESCOMSignData(doc.DataToSign,
            doc.DataToSign,
            props.signInfo.Thumbprint,
            true,
            doc.Signature,
            true,
            function (signInfo) {

                Fetcher("/DocumentApi/AddSignature",
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ SignInfo: signInfo, DocId: doc.DocumentId })
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.Success) {
                                doc.IsSigned = true;
                                doc.SignStatusDescription = 'Подписан';
                                props.setRowsData(rowsInput);
                            }
                            else
                                props.showErrorMessage(result.Error);

                            if (props.onDocSigned)
                                props.onDocSigned(doc.DocumentId, result.Error);
                        },
                        (error) => {
                            props.showErrorMessage(error);

                            if (props.onDocSigned)
                                props.onDocSigned(doc.DocumentId, result.Error);
                        }
                    );
            }
        );
    }

    /**
@property возвращающий набор кнопок для редактирования некоторого поля строки
@param index индекс элемента в массиве документов rowsData
@param nameField имя поля (колонка описывающая свойство документа)
*/
    const buttonsActions = (index, nameField) => {
        const rowsInput = [...props.rowsData];
        const field = rowsInput[index][nameField];

        return (
            <>
                <img src="/Content/images/edit.png" alt="Редактировать" className="fl_icon fl_edit actions-button"
                    style={{ display: field.Mode == 'View' ? "inline" : "none" }}
                    onClick={() => { editColumn(index, nameField); }}
                />
                <img src="/Content/images/fl_okey.png" alt="Сохранить изменения" className="fl_icon fl_save actions-button"
                    style={{ display: field.Mode == 'Edit' ? "inline" : "none" }}
                    onClick={() => {
                        saveChanges(index, nameField);
                    }}
                />
                <img src="/Content/images/fl_cancel.png" alt="Отменить изменения" className="fl_icon fl_cancel actions-button"
                    style={{ display: field.Mode == 'Edit' ? "inline" : "none" }}
                    onClick={() => { cancelChanges(index, nameField); }}
                />
            </>
        );
    }

    return (

        props.rowsData.map((data, index) => {
            const 
                {
                    DocumentId,
                    DocumentTitle,
                    Comment,
                    DocumentFile,
                    DisplayNumber,
                    DocumentFileName,
                    IsAutoSave,
                    IsError,
                    IsSigned,
                    SignStatusDescription,
                    Signature,
                    DataToSign,
                    DocumentLink
                } = data;

            return (

                 <tr key={DisplayNumber}>
                    <td>
                        {DocumentTitle.Mode == 'Edit' ?
                            <div class="div_actions">
                                <input type="text" name={'DocumentTitle_' + DisplayNumber}
                                    placeholder="введите название документа" className="form-control app-form-control"
                                    value={DocumentTitle.Value}
                                    onChange={(evnt) => (handleChange(index, evnt))}
                                />
                                {IsAutoSave && props.isEditable && buttonsActions(index, 'DocumentTitle')}
                            </div>
                            :
                            <div class="div_actions">
                                <label>{DocumentTitle.Value}</label>
                                {props.isEditable && buttonsActions(index, 'DocumentTitle')}
                            </div>
                            }
                    </td>
                    <td>
                        {Comment.Mode == 'Edit' ?
                            <div class="div_actions">
                                <textarea name={'Comment_' + DisplayNumber} className="form-control app-form-control"  
                                    value={Comment.Value}
                                    rows={10} required onChange={(evnt) => (handleChange(index, evnt))}>
                                </textarea>
                                {props.isEditable && IsAutoSave && buttonsActions(index, 'Comment')}
                            </div>
                            :
                            <div class="div_actions">
                                <label>{Comment.Value}</label>
                                {props.isEditable && buttonsActions(index, 'Comment')}
                            </div>
                            }
                    </td>
                    <td>
                        {DocumentFile.Mode != 'View' ?
                            <div>
                                <label htmlFor={"file" + index} className="fl_label_filename btn btn-sm btn-primary">
                                    <input id={"file" + index} type="file" style={{ display: 'none' }}
                                        onChange={(evnt) => (handleFileChange(index, evnt))} />
                                    Выбрать файл 
                                </label> 
                                {DocumentFileName}
                            </div>
                            :
                            <div class="div_actions">
                                <label className="fl_label_filename"><a href={DocumentLink}>{DocumentFileName}</a></label>                              
                            </div>
                        }
                        {props.signInfo.CurrentUserCanSign && <div className="signData">
                            <input type="hidden" id={"hdnSignInfo_" + index} value={DataToSign} />
                            <input type="hidden" id={"hdnExistingSignData_" + index} value={Signature} />
                        </div>}
                            
                    </td>
                    <td>
                        <div className="sign-container">
                            {IsSigned ?
                                (<span> {SignStatusDescription} </span>) :
                                (props.isEditable && props.signInfo.CurrentUserCanSign && DataToSign
                                    ? <a role="button" onClick={(e) => { handleSignProtocol(index) }} title="Подписать"> Подписать</a>
                                    : (DocumentFile.Value ? <span><strong>не подписан</strong></span>
                                                          : ''))
                            }
                        </div>

                    
                    </td>
                    {props.isEditable &&
                        <td class="actions">
                        <button className="btn btn-outline-danger" onClick={() => (deleteTableRows(index))}>x</button>&nbsp;&nbsp;
                        <button className="btn btn-sm btn-primary" onClick={(e) => (sendFileToServer(index, e))}
                            style={{ padding: '7px', display: DocumentFile.Mode != 'View' ? 'inline' : 'none' }}>
                            {DocumentFile.Mode == 'Uploading'
                                ? (<img src="/Content/images/loading_sm.gif" style={{ width: '20px' }} />)
                                : (<img src="/Content/images/upload.png" alt="Загрузить файл на сервер" style={{ width: '20px' }} />)}
                        </button>
                    </td>
                        }
                 </tr>
            )
        })

    )

}
export default TableRows;