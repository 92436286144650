import Fetcher from "../Common/Fetch";
import { IAppChangeStatusModel, ITradeApplication } from "./types";

export function GetTradeApplicationPromise(lotId: Number) {

    return Fetcher("/TradeApplicationApi/GetTradeApplication?lotId=" + lotId.toString() , {
        method: 'GET', headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.json());
}

export function SaveTradeApplication(app: ITradeApplication) {

    return Fetcher("/TradeApplicationApi/SaveTradeApplication", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(app)
    })
        .then(res => res.json());
}

export function Apply(app: ITradeApplication) {

    return Fetcher("/TradeApplicationApi/Apply", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(app)
    })
        .then(res => res.json());
}

export function CallAway(app: ITradeApplication) {

    return Fetcher("/TradeApplicationApi/CallAway", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(app)
    })
        .then(res => res.json());
}

export function GetTradeApplicationAcceptancePromise(lotId: Number) {

    return Fetcher("/TradeApplicationApi/GetTradeApplicationsForAcceptance?lotId=" + lotId.toString(), {
        method: 'GET', headers: { 'Content-Type': 'application/json' }
    })
    .then(res => res.json());
}

export function TradeApplicationChangeStatus(model: IAppChangeStatusModel) {

    return Fetcher("/TradeApplicationApi/TradeApplicationChangeStatus", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    })
        .then(res => res.json());
}

export function GeneratePartyDesicionProtocol(lotId: Number) {

    return Fetcher("/TradeApplicationApi/GeneratePartyDesicionProtocol", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ LotId: lotId })
    })
        .then(res => res.json());
}

export function GetPartyDesicionProtocol(lotId: Number) {

    return Fetcher("/TradeApplicationApi/GetPartyDesicionProtocol?lotId=" + lotId.toString(), {
        method: 'GET', headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json());
}

export function FinishPartyDesicion(lotId: Number) {

    return Fetcher("/TradeApplicationApi/FinishPartyDesicion", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ LotId: lotId })
    })
        .then(res => res.json());
}

