/* eslint-disable prettier/prettier */
import React, { useState, useEffect, ChangeEventHandler, FocusEventHandler } from "react";
import { ErrorMessage } from "formik";
import { ISelectListItem } from "../types";
import $ from "jquery";

interface IPropsType {
    label?: string,
    name: string,
    value: string | number | Array<string | number>,
    options: Array<ISelectListItem>,
    required: boolean,
    removeValidationMsg?: boolean,
    onChange?: ChangeEventHandler<Element>,
    onBlur?: FocusEventHandler<Element>,
    multiple?: boolean,
    optionLabel?: string,
    tooltipText?: string,
    containerClass?: string,
    additionalClass?: string,
    onSetValue?: Function,
    errorMessage?: string,
    disabled: boolean
}

function select(props: IPropsType) {
    const stdClass = (!props.removeValidationMsg && props.errorMessage ? "is-invalid " : "") + 'customSelect ';
    const [selectedValue, setSelectedValue] = useState(props.value);
    const [firstLoad, setFirstLoad] = useState(true);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {

        if (firstLoad) {
            $('select[name="' + props.name + '"]').niceSelect();
            $('select[name="' + props.name + '"]').change(function (e) {
                setSelectedValue(this.value);

                if (this.value && this.value != "0") {
                    setIsSelected(true);
                }
                else {
                    setIsSelected(false);
                }
            });
            setFirstLoad(false);
        }
        else {
            $('select[name="' + props.name + '"]').niceSelect('update');
        }

        if (!props.options || props.options.length == 0) {
            setIsSelected(false);
        }
        else if (props.options.some(x => x.Selected)) {
            setIsSelected(true);
        }


        

    }, [props.options]);

    useEffect(() => {
        if (!firstLoad) {
            props.onSetValue(props.name, selectedValue);
        }
    }, [selectedValue]);

    return (
        <div className={props.containerClass + (isSelected ? ' selected-select customSelectWrapper' : 'customSelectWrapper')} style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
            {props.label && <span className="inputName">{props.label}</span>}

            <select
                name={props.name}
                //onChange={props.onChange}
                onBlur={props.onBlur}
                multiple={props.multiple}
                className={props.additionalClass ? stdClass + props.additionalClass : stdClass}
                //disabled={props.disabled}>
            >
                {props.optionLabel &&
                    <option value={""}>{props.optionLabel}</option>}
                {props.options && props.options.map(item =>
                    <option key={item.Value} value={item.Value} selected={Array.isArray(props.value) ? props.value.filter(v => v == item.Value).length > 0 : props.value == item.Value} disabled={item.Disabled}>{item.Text}</option>
                )}
            </select>
            {!props.removeValidationMsg && props.errorMessage && <p className="errorMessage defaultDescription">{props.errorMessage}</p>}
        </div>

        //<div className="form-group">
        //    <label {...(props.tooltipText ? { "data-toggle": "tooltip", title: props.tooltipText } : {})}>
        //        {props.label} {props.required && <span className="requirement">(*)</span>}:
        //    </label>
            //<select
            //    name={props.name}
            //    onChange={props.onChange}
            //    onBlur={props.onBlur}
            //    multiple={props.multiple}
            //    className="form-control custom-field" >
            //    {props.optionLabel &&
            //        <option value={""}>{props.optionLabel}</option>}
            //    {props.options && props.options.map( item =>
            //        <option key={item.Value} value={item.Value} selected={ Array.isArray(props.value)? props.value.filter(v => v == item.Value).length>0:props.value == item.Value} disabled={item.Disabled}>{item.Text}</option>
            //    )}
            //</select>

        //    {!props.removeValidationMsg && <ErrorMessage name={props.name} component="span" className="field-validation-error"/>}
        //</div>
    );

}

export default select;