import React, { useState, useEffect } from "react";
import InputMask from 'react-input-mask';
import { fetcherPOSTJSON } from "../Common/Fetch";

function ContactForm() {
    const [phone, setPhone] = useState('+7 (___) ___-__-__');
    const [isPhone, setIsPhone] = useState<boolean | null>(null);
    const [name, setName] = useState('');
    const [isName, setIsName] = useState<boolean | null>(null);
    const [message, setMessage] = useState('');
    const [isMessage, setIsMessage] = useState<boolean | null>(null);
    const [checkboxActive, setCheckboxActive] = useState(false);
    const [isAgree, setIsAgree] = useState<boolean | null>(null);
    const [isProgress, setIsProgress] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handlePhone = (e) => {
        setPhone(e.target.value);
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleMessage = (e) => {
        setMessage(e.target.value);
    };

    // Валидация полей
    const validateFields = () => {
        setIsPhone(!phone.includes('_'));
        setIsName(name.trim() !== '');
        setIsMessage(message.trim() !== '');
        setIsAgree(checkboxActive);
    };

    // Проверка валидации и отправка заявки
    const submitForm = (e) => {
        e.preventDefault();

        validateFields();

        if (isPhone && isName && isMessage && isAgree) {
            const params = {
                chat_id: -1002061148970,
                text: `URL: ${document.URL}\n\n Имя: ${name}\n Номер телефона: ${phone}\n Комментарий: ${message}`,
            };

            fetch('https://api.telegram.org/bot7064703080:AAGVqAkyT6-Gy1Y-dMEf0XPM23Ci1lkvx88/sendMessage', {
                method: 'POST',
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => console.log('success feedback' + params + response))
                .catch((error) => {
                    console.error('Error sending message:', error);
                });
        }
    };

    // Проверяет валидацию полей при потере фокуса
    const handleBlur = (fieldName) => {
        if (fieldName === 'phone') setIsPhone(!phone.includes('_'));
        if (fieldName === 'name') setIsName(name.trim() !== '');
        if (fieldName === 'message') setIsMessage(message.trim() !== '');
        if (fieldName === 'agree') setIsAgree(checkboxActive);
    };

    return (
        <>
            <form id="feedBackForm" onSubmit={submitForm}>
                <div className="topInputs">
                    <div className="leftInputs_wrapper">
                        <div className="nameInput">
                            <span>Ваше имя*</span>
                            <input
                                onChange={handleName}
                                onBlur={() => handleBlur('name')}
                                type="text"
                                placeholder="Имя пользователя"
                                className={isName === false ? 'error' : ''}
                            />
                        </div>
                        <div className="telInput">
                            <span>Телефон*</span>
                            <InputMask
                                id="phone"
                                mask="+7 (999) 999-99-99"
                                onChange={handlePhone}
                                onBlur={() => handleBlur('phone')}
                                placeholder="+7 (___) ___-__-__"
                                className={isPhone === false ? 'error' : ''}
                            />
                        </div>
                    </div>
                    <div className="commentInput">
                        <span>Комментарий*</span>
                        <textarea
                            onChange={handleMessage}
                            onBlur={() => handleBlur('message')}
                            placeholder="Какой у вас вопрос?"
                            className={isMessage === false ? 'error' : ''}
                        ></textarea>
                    </div>
                </div>
                <div className="bottomElements">
                    <div
                        className={`feedback-checkbox ${isAgree === false ? 'error' : ''}`}
                    >
                        <div
                            className={checkboxActive ? 'customCheckboxWhiteActive' : 'customCheckboxWhite'}
                            onClick={() => {
                                setCheckboxActive(!checkboxActive);
                                setIsAgree(!checkboxActive);
                            }}
                        ></div>
                        <a target="_blank" href="https://realty.viomitra.ru/conf1">
                            Я&nbsp;прочитал(а) и&nbsp;согласен(на) с&nbsp;Политикой обработки персональных данных
                        </a>
                    </div>
                    <button type="submit">
                        {isProgress ? (
                            <img src="/Content/images/loading_sm.gif" style={{ width: '20px' }} />
                        ) : (
                            'Отправить данные'
                        )}
                    </button>
                </div>
            </form>

            {isSuccess &&
                <div className="defaultPopupWrapper">
                    <div className="defaultPopup">
                        <button className="closePopupButton" onClick={() => { setIsSuccess(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                            </svg>
                        </button>

                        <div className="popupTitle">
                            <h4>
                                Успешно!
                                <hr></hr>
                            </h4>
                            <p>Пожалуйста, подождите немного, наш агент скоро свяжется с вами!</p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ContactForm;