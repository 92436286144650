import React, { useState, useEffect } from "react";
import { fetcherGET } from "../Common/Fetch"

interface NewsItem {
    Id: number,
    Title: string,
    Summary: string,
    SeoUrl: string,
    Content: string,
    PublicationDate: string,
    ImageUrl: string,
    Image: any
}

function EventsList() {

    const [newsList, setNewsList] = useState<Array<NewsItem>>([]);

    //Получение новостей
    useEffect(() => {
        fetcherGET('/News/GetNewsList', (result) => {
            console.log(result);
            setNewsList(result);
        });
    }, []);

    return (
        <>
            <h4 className="purpleTextHead">Лента событий</h4>
            {newsList.length > 0 ? (
                newsList.map((item) => (
                    <a href={item.SeoUrl}>
                        <span className="defaultComment">{item.PublicationDate}</span>
                        <p className="defaultDescription">{item.Title}</p>
                    </a>
                ))
            ) : (
                <p className="errorText">Событий не найдено</p>
            )}
        </>
    );
}

export default EventsList;