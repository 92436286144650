import React, { MouseEventHandler, useEffect, useState } from "react";

interface IPropsType {
    currentPage: number;
    totalCount: number;
    pageSize: number;
    toPage: (page: number) => void;
    additionalClass?: string;
}

function Paging(props: IPropsType) {
    const [visiblePages, setVisiblePages] = useState<number[]>([]);
    const totalPages = Math.ceil(props.totalCount / props.pageSize);


    //Логика отображения видимых страниц в пагинации
    useEffect(() => {
        const calculateVisiblePages = () => {
            const maxVisiblePages = 5;

            if (totalPages <= maxVisiblePages) {
                return Array.from({ length: totalPages }, (_, i) => i + 1);
            }

            const startPage = Math.max(
                1,
                Math.min(
                    props.currentPage - Math.floor(maxVisiblePages / 2),
                    totalPages - maxVisiblePages + 1
                )
            );

            const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

            const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

            if (startPage > 1) pages.unshift(1);
            if (endPage < totalPages) pages.push(totalPages);

            return pages;
        };

        setVisiblePages(calculateVisiblePages());
    }, [props.currentPage, totalPages]);

    const handlePageClick = (page: number) => {
        if (page !== props.currentPage) props.toPage(page);
    };

    return (
        totalPages > 1 && (
            <div className='paging'>
                {props.currentPage > 1 && (
                    <div className="pagingPrev" onClick={() => props.toPage(props.currentPage - 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="12" viewBox="0 0 26 12" fill="none">
                            <path d="M25 7C25.5523 7 26 6.55228 26 6C26 5.44772 25.5523 5 25 5V7ZM0 6L10 11.7735V0.226497L0 6ZM25 5L9 5V7L25 7V5Z" fill="#170021" fill-opacity="0.8" />
                        </svg>
                    </div>
                )}

                <div className="numPages">
                    {visiblePages.map((page, index) => (
                        <React.Fragment key={page}>
                            {index > 0 && visiblePages[index - 1] + 1 !== page && <span>...</span>}
                            <span
                                className={page === props.currentPage ? "active" : ""}
                                onClick={() => handlePageClick(page)}
                            >
                                {page}
                            </span>
                        </React.Fragment>
                    ))}
                </div>

                {props.currentPage < totalPages && (
                    <div className="pagingNext" onClick={() => props.toPage(props.currentPage + 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="12" viewBox="0 0 26 12" fill="none">
                            <path d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM26 6L16 0.226497V11.7735L26 6ZM1 7H17V5H1V7Z" fill="#170021" fill-opacity="0.8" />
                        </svg>
                    </div>
                )}
            </div>
        )
    );
};

export default Paging;