import { Nullable, IDocumentModel } from "../Common/types";

export enum ApplicationStatusses {
    New = 1,
    Approved = 2,
    Declined = 3 ,
    WaitingForConfirmation = 4,
    Cancelled = 5
}

export interface ITradeApplication {
    Id : number,
    ConfirmAgreement: boolean,
    ApplyDate: Date,
    CreateDate: Date,
    LotId: Nullable<number>,
    Documents: Array<IDocumentModel>,
    StatusId: Nullable<ApplicationStatusses>,
    StatusName: string,
    ParticipantName: string,
    Bet: Nullable<number>
}

export interface IAcceptanceModel {
    Applications: Array<ITradeApplication>,
    ShowBets: boolean,
    AllowProtocolGeneration: boolean
}

export interface IAppChangeStatusModel {
    Id: Number,
    Status: Number,
    Reason: string
}