import React, { createElement, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import PicturesCropResult from './PicturesCropResult'
import $ from 'jquery';


const PicturesCrop = (props) => {
    const [file, setFile] = useState(null)
    const [image, setImage] = useState(null)
    const [imageName, setImageName] = useState(null)
    const [result, setResult] = useState(null)
    const [showResult, setShowResult] = useState(null)
    const [cropWidth, setCropWidth] = useState(null)
    const [cropHeight, setCropHeight] = useState(null)
    const [y, setY] = useState(0)
    const [x, setX] = useState(0)
    const [crop, setCrop] = useState(null)
    const [newImg, setNewImg] = useState(false)
    const [trueRot, setTrueRot] = useState(false)
    const [ctx, setCtx] = useState(null)
    const [canvas, setCanvas] = useState(null)
    const [mimeType, setMimeType] = useState(null);


    
    useEffect(() => {
        setCrop({
            unit: 'px',
            width: cropWidth,
            height: cropHeight,
            x: x,
            y: y,
        })
    }, [cropWidth, cropHeight, x, y])
   


   
    window.addEventListener('resize', function() {
        onImageLoaded(image)
    });

    useEffect(() => {
        if (props.img) {
            setImageName(props.img.name)
            setFile(URL.createObjectURL(props.img))
            setNewImg(true)
            setMimeType(props.img.type);
        }
    }, [props.img])

    const onImageLoaded = (e) => {
        //let width = e.width;
        //let height = e.height;

        if (newImg) {
            setNewImg(false)
            const canvas = document.createElement("canvas");
            setCanvas(canvas)
            setCtx(canvas.getContext("2d"));
        }
        setImage(e)
        setCropWidth(e.width)
        setCropHeight(e.height)
        setX(0)
        setY(0)

        //if (width / height >= 16 / 9) {
        //    setCropWidth(e.height / 9 * 16)
        //    setCropHeight(e.height)
        //    setX((e.width / 2 - (e.height / 9 * 16) / 2))
        //    setY(0)
        //} else {
        //    setCropWidth(e.width)
        //    setCropHeight(e.width / 16 * 9)
        //    setY((e.height / 2 - (e.width / 16 * 9) / 2))
        //    setX(0)
        //}
    }

    function getCroppedImg() {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const pixelRatio = window.devicePixelRatio;
        // canvas.width = canvas.width * pixelRatio;
        // canvas.height = canvas.height * pixelRatio;
        // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        const base64Image = canvas.toDataURL(mimeType)
        setResult(base64Image)
        setShowResult(true)
    }

    function rotateForward(angle, verticalOrientation) {
        if (verticalOrientation) {
            debugger
            canvas.width = image.naturalHeight;
            canvas.height = image.naturalWidth;
            setTrueRot(false)
        } else {
            canvas.width = image.naturalHeight;
            canvas.height = image.naturalWidth;
            setTrueRot(true)
        }

        ctx.imageSmoothingQuality = "high";
        let x = canvas.width;
        let y = 0;
        ctx.translate(x, y);
        ctx.rotate(angle);
        ctx.drawImage(
            image,
            0,
            0,
            canvas.height,
            canvas.width,
        );
        const base64Image = canvas.toDataURL(mimeType)
        setFile(base64Image)
    }


    let rotateBack = (angle, verticalOrientation) => {
        if (verticalOrientation) {
            canvas.width = image.naturalHeight;
            canvas.height = image.naturalWidth;
            setTrueRot(false)
        } else {
            canvas.width = image.naturalHeight;
            canvas.height = image.naturalWidth;
            setTrueRot(true)
        }
        ctx.imageSmoothingQuality = "high";
        let x = 0;
        let y = canvas.height;
        ctx.translate(x, y);
        ctx.rotate(-angle);
        ctx.drawImage(
            image,
            0,
            0,
            canvas.height,
            canvas.width,
        );
        const base64Image = canvas.toDataURL(mimeType)
        setFile(base64Image)
    }


    const load = () => {
        fetch(result)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], imageName, { type: mimeType })
                props.uploadImage(file)
            })
        props.setCropActive(false)
    }

    const setLoader = () => {
        console.log('load')
    }

    return (
        <div className="crop-image">
            <div className="wrapper-cropper d-flex flex-lg-column justify-content-between">
                {result && showResult ? (
                    <PicturesCropResult setLoader={props.setLoader ? props.setLoader : setLoader} setShowResult={setShowResult} load={load} result={result} setButtonText={props.setButtonText} />
                ) : (
                    <div className="container">
                        <div className="image-area">
                            <div className="crop-img">
                                {file && (
                                    <ReactCrop
                                        src={file}
                                        onImageLoaded={onImageLoaded}
                                        crop={crop}
                                        onChange={newCrop => setCrop(newCrop)}
                                    />
                                )}
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="button-rotate" onClick={() => rotateBack(1.5708, trueRot)}>
                                    <img src="/Content/images/rotate-arrow.svg" style={{ width: '20px' }} />
                                </div>
                                <div className="button-rotate" onClick={() => rotateForward(1.5708, trueRot)}>
                                    <img src="/Content/images/rotate-arrow.svg" style={{ width: '20px', transform: 'scale(-1, 1)' }} />
                                </div>
                            </div>
                        </div>
                        <div className="popupButtons">
                            <button className="defaultPinkButton" onClick={getCroppedImg}>Выбрать область</button>
                            <button className="defaultBlackBorderButton" onClick={() => props.setCropActive(false)}>Выйти</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

}

export default PicturesCrop