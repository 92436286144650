import React, {useState} from 'react';
import { useSignal, useSlot } from 'react-signal-slot';



const PicturesCropResult = (props) => {

    const [buttonText, setButtonText] = useState(null);

    useSlot(props.setButtonText, (text) => { setButtonText(text) });

    return (

        <div className='img-result-container d-flex flex-lg-column justify-content-between'>
            <div className='crop-img'>
                <img className='img-result' src={props.result} />
            </div>
            <div className='popupButtons'>
                <button className='defaultPinkButton' onClick={() => { props.load(), props?.setLoader(true) }}>{buttonText ? buttonText : 'Загрузить'}</button>
                <button className='defaultBlackBorderButton' onClick={() => props.setShowResult(false)}>Назад</button>
            </div>
        </div>

    )

}

export default PicturesCropResult