import React, { useState, useEffect } from "react";
import Fetcher from "../Common/Fetch";
import { IDimensions, IDeliveryStartPoint } from "../Common/types";

interface IPropsType {
    lotId: number;
}

function CDEKWidget(props: IPropsType) {
    const [token, setToken] = useState<string | null>(null);
    const [dimensions, setDimensions] = useState<IDimensions | null>(null);
    const [deliveryStartPoint, setDeliveryStartPoint] = useState<IDeliveryStartPoint | null>(null);

    //// Получение токена
    //useEffect(() => {
    //    const fetchToken = async () => {
    //        try {
    //            const response = await fetch("https://api.edu.cdek.ru/v2/oauth/token", {
    //                method: "POST",
    //                headers: { "Content-Type": "application/json" },
    //                body: JSON.stringify({
    //                    grant_type: "client_credentials",
    //                    client_id: "PPa4lbyK7PP9uiHhCOFZ5uka1jHW2Sf8",
    //                    client_secret: "gd38zcuTp6nHgTYi4LDVlNymRuTmh0K3",
    //                }),
    //            });

    //            if (response.ok) {
    //                const data = await response.json();
    //                setToken(data.access_token);
    //            } else {
    //                console.error("Ошибка получения токена:", response.statusText);
    //            }
    //        } catch (error) {
    //            console.error("Ошибка сети:", error);
    //        }
    //    };

    //    fetchToken();
    //}, []);

    // Получение данных лота
    useEffect(() => {
        const fetchLotData = async () => {
            try {
                const response = await Fetcher(`/TradeEditApi/GetDeliveryDetails?lotId=${props.lotId}`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                });

                if (response.ok) {
                    const data = await response.json();
                    const dimensionsObject = typeof data.UpdatedItem === "string" ? JSON.parse(data.UpdatedItem) : null;

                    if (dimensionsObject) {
                        setDimensions({
                            Width: dimensionsObject.Width,
                            Height: dimensionsObject.Height,
                            Length: dimensionsObject.Length,
                            Weight: dimensionsObject.Weight,
                        });
                        setDeliveryStartPoint({
                            City: dimensionsObject.FromCity,
                            Address: dimensionsObject.FromAddress,
                            CountryCode: "RU",
                        });
                    }
                }
            } catch (error) {
                console.error("Ошибка загрузки данных лота:", error);
            }
        };

        fetchLotData();
    }, [props.lotId]);

    // Инициализация виджета
    useEffect(() => {
        const initializeCDEKWidget = async (userLocation = null) => {
            const CDEKWidget = (window as any).CDEKWidget;

            if (CDEKWidget && dimensions && deliveryStartPoint) {
                const widget = new CDEKWidget({
                    from: {
                        country_code: deliveryStartPoint.CountryCode,
                        city: deliveryStartPoint.City,
                        address: deliveryStartPoint.Address,
                    },
                    to: userLocation
                        ? { latitude: userLocation.lat, longitude: userLocation.lng }
                        : undefined,
                    goods: [
                        {
                            width: dimensions.Width,
                            height: dimensions.Height,
                            length: dimensions.Length,
                            weight: dimensions.Weight,
                        },
                    ],
                    root: "cdek-map",
                    apiKey: "49ea5682-b2de-4233-86e3-e009cff7dfb4",
                    debug: true,
                    defaultLocation: userLocation
                        ? [userLocation.lng, userLocation.lat] // Координаты пользователя
                        : "Россия", // Запасной вариант — текстовое название страны
                    lang: "rus",
                    currency: "RUB",
                    onReady: () => console.log("Виджет готов"),
                    onChoose: (data) => console.log("Доставка выбрана:", data),
                    onError: (error) => {
                        console.error('Ошибка в CDEK Widget:', error);
                    },
                });
            }
        };

        if (dimensions && deliveryStartPoint) {
            // Попробуем получить местоположение пользователя
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    initializeCDEKWidget(userLocation);
                },
                () => {
                    // Если местоположение недоступно, используем запасной вариант
                    initializeCDEKWidget(null);
                }
            );
        }
    }, [dimensions, deliveryStartPoint]);

    return <div id="cdek-map"></div>;
}

export default CDEKWidget;