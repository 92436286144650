import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import history from "../history";
import { useSignal, useSlot } from 'react-signal-slot';
import PopupLoadPictures from "../Common/PopupLoadPictures/PopupLoadPictures";

import ListPicturesLoader from "../Common/ListPicturesLoader/ListPicturesLoader";

import UserDetailsTable from "../Admin/UserDetailsTable";

import ConfirmPopup from "../Common/ConfirmPopup/ConfirmPopup";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";


// данная модель используется для передечи через неё данных контроллеру и хранимой процедуре
// необходимо cтрого поддерживать соответвтие её полей классу Mithra.Data.Models.Catalog.CatalogModel
// так как этот класс сериализуется в json и передаётся на сервер, где его принимают в качетсве модели данных Mithra.Data.Models.Catalog.CatalogModel


function TestArea()
{
    useSlot('TestSignal', (type) => testSlot(value)); // subscribe the signal of "signal"

    const testSlot = (value) => {

    }

    //PopupLoadPictures

    const [data, setData] = useState(null);//файл который получаем из попапа
    const openPopup1 = useSignal(); //сигнал для открытия попапа (здесь вызывается при клике на "Тестировать")
    const setTitle1 = useSignal(); //сигнал для установки заголовка в попапе(не обязателен) (здесь вызывается при клике на "Тестировать")
    const setButtonText = useSignal(); //сигнал для установки текста для кнопки после импортировки и редактировании картинки(не обязателен)
    useSlot('sendFile1', (file) => { setData(file); console.log(file) }); //слот для получения файла из попапа

    //PopupLoadPictures


    //ListPicturesLoader

    useSlot('getPicturesList', (list) => { console.log(list) });

    //ListPicturesLoader


    //UserDetailsTable

    const handleChangeStatus = useSignal();
    const signalResultDeleteData = useSignal();
    const signalResultSendNotification = useSignal();

    useSlot('ChangeStatusChecking', (result) => {
        console.log(result);
        handleChangeStatus('handleChangeStatus', { Success: true });
    })
    useSlot('deleteAccount', (id) => {
        console.log(id)

        signalResultDeleteData('signalResultDeleteData', { Success: true })
    })
    useSlot('sendNotification', (data) => {
        console.log(data);

        signalResultSendNotification('signalResultSendNotification', { Success: true });
    })

    const [users, setUsers] = useState([
        {
            AccountId: 9,
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 10,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 8,
            FirstName: "Имя",
            LastName: "Фамилия22",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 11,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название2',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 7,
            FirstName: "Имя",
            LastName: "Фамилия33",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 11,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название3',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 6,
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 10,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 5,
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 10,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 4,
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 10,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 3,
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 10,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 2,
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 10,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
        {
            AccountId: 1,
            FirstName: "Имя",
            LastName: "Фамилия",
            MiddleName: "Отчество",
            Birthday: "2025-01-26T13:01:26.576455",
            CompanyName: "Компания",
            CompanyTypeId: 3,
            RegisterDate: "2025-01-26T13:01:26.576455",
            Phone: "92105340921880",
            Email: "email@email.com",
            StatusId: "2",
            OGRN: "215423543",
            OGRNIP: "0912543543",
            INN: "18756532",
            RegAddress: "адрес1",
            FactAddress: "адрес2",
            PassportSeries: "0-6548-4325",
            PassportNumber: "21047325-43",
            PassportDate: "2025-01-26T13:01:26.576455",
            PassportPlace: "место",
            AdditionalInfo: "действует на основании",
            StatusChecking: 10,
            BankRequisite: {
                BankName: "Банк",
                KPP: "32652354",
                Bic: "980216754",
                CorrespondentAccount: "Аккаунт",
                PersonalAccount: "Аккаунт1"
            },
            Documents: {
                DocumentId: 2,
                Name: 'Название',
                Comment: 'Комментарий',
                DocumentTypeId: 2,
                CreationDate: '2025-01-26T13:01:26.576455',
                IsSigned: true,
                SignStatusId: 3,
                SignStatusDescription: 'Подписан',
                DocumentTypeDescription: 'Какой-то тип',
                CurrentUserCanSign: true,
                SignatureLink: 'link',
                Signature: 'что-то',
                DataToSign: '2025-01-26T13:01:26.576455',
                DocumentLink: 'Ссылка'
            }
        },
    ])

    const SendData = useSignal();



    useSlot('SignalGetData', (data) => {
        console.log('Страница номер:' + data.page)
        if (data.page == 1) {
            SendData('SignalGetDataResult',
                {
                    Success: true,
                    Error: '',
                    totalRecords: 9,
                    UpdatedItem: users.slice(0, 5)
                }
            )
        } else {
            SendData('SignalGetDataResult',
                {
                    Success: true,
                    Error: '',
                    totalRecords: 9,
                    UpdatedItem: users.slice(4, 9)
                }
            )
        }
    })


    //UserDetailTable


    //ConfirmPopup
    const [result1, setResult1] = useState('')

    const openConfirm = useSignal();
    const desc = useSignal();

    useSlot('confirmPopup1', (bool) => { setResult1(String(bool)); console.log(bool) })

    const test1 = () => {
        desc('desc', 'Подтвердить действие');
        openConfirm('openConfirm', true);
    }
    //ConfirmPopup

    //NotificationPopup
    const [result2, setResult2] = useState('')

    const openNotification = useSignal();
    const desc1 = useSignal();

    useSlot('notifPopup1', (text) => { setResult2(text); openNotification('openNotification', false); })

    const test2 = () => {
        desc('desc1', 'Текст сообщения');
        openNotification('openNotification', true);
    }
    //NotificationPopup

    


    return (
        <React.Fragment>
            <div className="boxes">
                <div className="box popupLoadPictures">
                    <h4 className="purpleTextHead">Попап загрузки изображения</h4>
                    <PopupLoadPictures
                        signalOpenPopup1={'openPopup1'}
                        signalSendFile1={'sendFile1'}
                        signalSetTitle1={'setTitle1'}
                        signalSetButtonText={'setButtonText1'}
                    />
                    {data &&
                        <div className="data">
                            <p className="defaultDescription">Название файла: {data.name}</p>
                            <p className="defaultDescription">Размер: {(data.size / 1024 / 1024).toFixed(1)}МБ</p>
                            <p className="defaultDescription">Расширение файла: {data.type}</p>
                        </div>   
                    }
                    <button className="defaultPinkButton"
                        onClick={() => { openPopup1('openPopup1', true); setTitle1('setTitle1', 'Выберите файл'); setButtonText('setButtonText1', 'Загрузить') }}
                    >
                        Тестировать
                    </button>
                    
                    <img className="overview" src="../../images/testPageImages/testPageImage1.png"></img>

                    <div className="boxDescription">
                        <p className="defaultDescription">
                            Данный компонент нужен для загрузки изображений, изображение можно загрузить
                            методом drag-n-drop, либо же просто кликнуть на поле и выбрать файл.
                            После выбора файла, если его размер меньше 2МБ, дается возможность редактировать
                            зону у картинки, после чего создается объект File.
                            Компонент принимает два сигнала:
                            'Open popup' - для открытия попапа;
                            'setTitle' - для установки заголовка сверху(не обязательно);
                            'SetButtonText' - для текста у кнопки после загрузки и редактирования картинки(не обязательно);
                            А так же отправляет готовый файл через слот sendFile, слот принимает только сам файл.
                        </p>
                    </div>
                </div>

                <div className="box">
                    <h4 className="purpleTextHead">Загрузка изображений</h4>
                    <ListPicturesLoader />
                    <div className="boxDescription">
                        <p className="defaultDescription">
                            Компонент для загрузки нескольких изображений, принимает массив с объектами картинок в пропсах(не обязательно)
                            после загрузки и редактирования изображения она добавляется в общий массив Pictures и выводится ниже, 
                            каждую из картинок можно сделать главной(поставить на индекс 0), либо же удалить. Каждый раз когда
                            изменяется массив Pictures - его получает родительский компонент через signal.
                        </p>
                    </div>
                </div>
                <div className="box" style={{ "grid-column": '1/span2' }}>
                    <h4 className="purpleTextHead">Таблица для обработки персональных данных пользователей</h4>
                    <UserDetailsTable users={users} />

                    <div className="boxDescription">
                        <p className="defaultDescription">
                            Для получения массива пользователей компонент: <br></br>
                            -Отправляет сигнал SignalGetData в который передается объект: <br></br>
                            page: номер активной страницы, <br></br>
                            countRecord: количество отображаемых строк на странице, <br></br>
                            SortField: поле сортировки, <br></br>
                            Filters: фильтры <br></br>

                            -После ждет ответ от сигнала SignalGetDataResult с объектом: <br></br>
                            Success: boolean,<br></br>
                            Error: текст,<br></br>
                            totalRecords: сколько всего пользователей найдено,<br></br>
                            UpdatedItem: массив с объектами пользователей<br></br>
                            
                            Возможности: <br></br>
                            -Каждую строку пользователя можно раскрыть и посмотреть данные о нём <br></br>
                            -У подтвержденных пользователей появляется соответствующая иконка справа-снизу <br></br>
                            -Пользователю можно отправить уведомление в действиях <br></br>
                            -Пользователя можно удалить <br></br>
                            -Компонент получается массив с объектами пользователей <br></br>
                        </p>
                    </div>
                </div>
                <div className="box">
                    <h4 className="purpleTextHead">Попап подтвреждения</h4>
                    <p className="defaultDescription">Результат: {result1}</p>
                    <button className="defaultPinkButton" onClick={test1}>Тест</button>
                    <ConfirmPopup
                        signalOpenPopup="openConfirm"
                        description="desc"
                        signalConfirm="confirmPopup1"
                    />

                    <div className="boxDescription">
                        <p className="defaultDescription">
                            Компонент для подтверждения действий.<br></br> 
                            Принимает три пропса:<br></br> 
                            -название сигнала для открытия попапа (получает boolean)<br></br> 
                            -название сигнала для описания <br></br> 
                            -название слота для получения true/false <br></br>
                        </p>
                    </div>
                </div>
                <div className="box">
                    <h4 className="purpleTextHead">Попап уведомления</h4>
                    <p className="defaultDescription">Результат: {result2}</p>
                    <button className="defaultPinkButton" onClick={test2}>Тест</button>
                    <NotificationPopup
                        signalOpenPopup="openNotification"
                        description="desc1"
                        signalSendText="notifPopup1" />

                    <div className="boxDescription">
                        <p className="defaultDescription">
                            Компонент для отправки уведомления. <br></br>
                            Принимает три пропса: <br></br>
                            -название сигнала для открытия попапа (получает boolean) <br></br>
                            -название сигнала для описания <br></br>
                            -название слота для получения текста <br></br>

                            !!! После получения текста нужно еще раз отправить первый сигнал <br></br>
                            !!! с bool = false (чтобы закрыть его) <br></br>
                        </p>
                    </div>
                </div>
                <div className="box"></div>
                <div className="box"></div>
                <div className="box"></div>
            </div>
        </React.Fragment>
    );
}

export default TestArea;