import React, { useState, useEffect, ChangeEventHandler, FocusEventHandler } from "react";

interface IPropsType {
    label: string,
    name: string,
    value: string,
    required: boolean,
    removeValidationMsg?: boolean,
    onChange: ChangeEventHandler<HTMLTextAreaElement>,
    onBlur?: FocusEventHandler<HTMLTextAreaElement>,
    tooltipText?: string,
    children?: React.ReactNode,
    placeholder?: string,
    errorMessage?: string,
    columnClass?:string
}

function textArea(props: IPropsType) {
    const stdInputClass = (!props.removeValidationMsg && props.errorMessage ? " is-invalid" : "") + " form-control auto-height-form-control app-form-control";

    return (
        <div className="row gutter-row-10 align-items-center mb-4 pb-3">
            <div className={props.columnClass}>
                <div className="input-custom mb-2">
                    <textarea required
                        name={props.name}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        className={stdInputClass}
                        placeholder={props.placeholder}
                        cols={30}
                        rows={6}
                        defaultValue={props.value}>
                    </textarea>
                    {!props.removeValidationMsg && props.errorMessage && <p className="always-block invalid-feedback mb-0 pt-2">{props.errorMessage}</p>}
                </div>
                
            </div>
            {props.children}
        </div>
        //<div className="form-group">
        //    <label {...(props.tooltipText ? { "data-toggle": "tooltip", title: props.tooltipText } : {})}>
        //        {props.label} {props.required && <span className="requirement">(*)</span>}:
        //    </label>
            //<textarea
            //    name={props.name}
            //    onChange={props.onChange}
            //    onBlur={props.onBlur}
            //    className="form-control custom-field" >
            //    {props.value}
            //</textarea>

        //    {!props.removeValidationMsg && <ErrorMessage name={props.name} component="span" className="field-validation-error"/>}
        //</div>
    );

}

export default textArea;