

export interface Profile {
    AccountId: Number,
    FirstName: String,
    LastName: String,
    MiddleName: String,
    Birthday: String,
    CompanyName: String,
    CompanyTypeId: Number,
    RegisterDate: String,
    Phone: String,
    Email: String,
    StatusId: Number,
    OGRN: String,
    OGRNIP: String,
    INN: String,
    RegAddress: String,
    FactAddress: String,
    PassportSeries: String,
    PassportNumber: String,
    PassportDate: String,
    PassportPlace: String,
    AdditionalInfo: String,   // Действует на основании (для представителей юридического лица)
    StatusChecking: Number,  // статус проверки.
    BankRequisite: {
        BankName: String,
        KPP: String,
        Bic: String,
        CorrespondentAccount: String,
        PersonalAccount: String
    }
    Documents: IdocumentModel
}

export interface IdocumentModel {
    DocumentId: number,
    Name: string,
    Comment: string,
    DocumentTypeId: number,
    CreationDate: Date,
    IsSigned: boolean,
    SignStatusId: number,
    SignStatusDescription: string,
    DocumentTypeDescription: string,
    CurrentUserCanSign: boolean,
    SignatureLink: string,
    Signature: string,
    DataToSign: string,
    DocumentLink: string
}

export enum StatusChecking {
    RegistrationDeclined = 3,
    WaitingConfirmPersonalData = 10,
    PersonalDataComfirmed = 11
}

export var StatusCheckingDescription: { [key: StatusChecking]: string; } = {};
StatusCheckingDescription[StatusChecking.RegistrationDeclined] = 'В регистрации отказано';
StatusCheckingDescription[StatusChecking.WaitingConfirmPersonalData] = 'Ожидание подтверждения';
StatusCheckingDescription[StatusChecking.PersonalDataComfirmed] = 'Подтвержден';