import React, { useState, useEffect, ChangeEventHandler, FocusEventHandler } from "react";
import { ISelectListItem, Nullable } from "../types"
import Checkbox from "./Checkbox"

interface IPropsType {
    items: Array<ISelectListItem>,
    selectedItems: Array<number>,
    onChange: (selectedItem: ISelectListItem, add: boolean) => void
    allowMultiple: boolean,
    showValidation?: boolean,
    errorMessage?: string,
    name: string,
    checkBoxAdditionalClass: string,
    rowCount: number,
    disabled: boolean
}

function checkboxList(props: IPropsType) {
    const [separatedItems, setSeparatedItems] = useState<Array<Array<ISelectListItem>>>([]);
    
    useEffect(() => { console.log('tetee', props.name);
        var resultArray = [];
        if (props.items && props.items.length > 0) {

            var i = 0;


            while ((i + props.rowCount) <= props.items.length) {
                resultArray.push(props.items.slice(i, i + props.rowCount));
                i += props.rowCount;
            }
           
            if (i < props.items.length) {
                resultArray.push(props.items.slice(i));
            }
            
            setSeparatedItems(resultArray);
           
            //for (var i = 0; i < props.items.length; i++) {
            //    if ((i + 1) / props.rowCount == 0) {
            //        resultArray.push(props.items.slice());
            //    }
            //}
        }

    }, [props.items]);

    return (
        <React.Fragment>
            <div className="row">
                
            {separatedItems && separatedItems.map(x => {
                return (
                    
                    <div className={`col-${ props.name === "Filter115" ? "md-6" : separatedItems.length === 2 ? "md-3" : (separatedItems.length === 3 ? "sm" : `md-${(12/separatedItems.length)}`)}` }>
                        {/*style={separatedItems.length === 2 ? {marginRight: "85px"} : {}}*/}
                        
                      {/*<div className={"col-md-" + (12 / separatedItems.length)}>*/}
                      {/*<div className="col-md-auto">*/}
                        {x.map(y => {
                            
                            return (
                                <React.Fragment>
                                   
                                <Checkbox additionalClass={props.checkBoxAdditionalClass} label={y.Text}
                                name={props.name + y.Value}
                                value={props.selectedItems.indexOf(parseInt(y.Value)) > -1}
                                disabled={props.disabled}
                                onChange={(e) => {
                                    props.onChange(y, e.target.checked);
                                    
                                }} />
                                </React.Fragment>
                                    )
                           
                        })}
                    </div>)
            })}
                {props.showValidation && <p className="always-block invalid-feedback mb-0 pt-2 col-md-12">{props.errorMessage}</p>}
               
            </div>
        </React.Fragment>

    );

}

export default checkboxList;