/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from "react";
import TableRows from "./Row";
import Fetcher from "../Fetch";
import "./style.css";
import { IDocumentModel } from "../types";

/**
   @interface IPropsType - интерфейс предоставляющий некоторые поля необходимые компоненту AddDeleteTableRows для его работы. 
   @property lotId - идентификатор лота. Мы получаем его при автосохранении лота из основного компонента, где внедрен нам компонент AddDeleteTableRows 
   @property prefix - префикс для задания id тегам компонента
   @property data - массив документов, который мы получаем из основного компонента и должны его отбразить в своей таблицы, предоставив вохможности редактирования
   @property onAddDocument - ссылка на метод, который мы вызовем при попытке добавить строку к таблице. Нам необходимо до этого получить lotId
   если он не был ещё получен. Метод должен получить в параметрах lotId из основного компонента где он будет вызван.
*/

interface IPropsType {
    idProperty: string,
    idValue: number,
    prefix: string,
    data: IDocumentModel[],
    isEditable: boolean,
    onAddDocument: (string) => void
    onDocSigned?: (id: number, error: string) => void
}

/**
   @function AddDeleteTableRows - функциональный компонет представляющий из себя таблицу с документами. 
   Позволяющий добавлять новые документы, удалять имеющиеся и редактировать поля документа.
   !эцп - электронно цифровая подпись. Далее будем именовать сокращенно эцп.
*/
function AddDeleteTableRows(props: IPropsType) {

    // свойство которое хранит в себе список документов таблицы и следит за изменениями в этих документах. Реагируя на изменения перерисовкой частей комопннета.
    const [rowsData, setRowsData] = useState([]);
    // свойство которое хранит в себе информацию о эцп пользователя
    const [signatureInfo, setSignatureInfo] = useState({});
    // некоторый флаг необходимоый нам для единоразового вызова метода, получающего информацию о эцп пользователя
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    //режим таблицы: блокирована на время заврешения асинхронной операции или доступна пользователю
    const [mode, setMode] = useState({ isBlock: false, message: '' });
    // сообщени еоб ошибки
    const [error, setError] = useState({ isError: false, message: '' });
    // информационное сообщение
    const [info, setInfo] = useState({ isShow: false, message: '' });

    if (props.data && rowsData.length == 0) {
        let length = props.data.length;

        for (let i = 0; i < length; i++) {
            var item = props.data[i];
            console.log(item);

            // Проверка на undefined
            var path = item.Path ? item.Path.split('/') : [];

            var rowsInput = {
                DocumentId: item.DocumentId,
                DocumentTitle: { Mode: 'View', Value: item.Name, OldValue: '' },
                Comment: { Mode: 'View', Value: item.Comment, OldValue: '' },
                DocumentFile: { Mode: 'View', Value: null, OldValue: null },
                DisplayNumber: rowsData.length,
                DocumentFileName: path.length > 0 ? path[path.length - 1] : "", // Проверка на пустой массив
                IsAutoSave: true,
                IsError: false,
                IsSigned: item.IsSigned,
                SignStatusDescription: item.SignStatusDescription,
                Signature: item.Signature,
                DataToSign: item.DataToSign,
                DocumentLink: item.DocumentLink
            }
            rowsData.push(rowsInput);
        }
    }

    // маленький hook метод который отработает у нас всего доин раз для получения эцп пользователя
    useEffect(() => {
        if (isFirstLoad) {
            getSignInfo(() => { setIsFirstLoad(false); console.log(signatureInfo); });
        }

        //if (props.data) {
        //    setRowsData([...rowsData, props.data])
        //}
    }, [isFirstLoad]);


    /**
       @property метод запрашивающий с сервера информацию о эцп пользователя
       @param callback callback функция которая должна быть вызвана при получении ответа от сервера
   */
    const getSignInfo = (callback) => {
        Fetcher("/DocumentApi/GetCurrentUserSignData",
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setSignatureInfo(result);
                    callback();
                }
            )
            .catch((exception) => {
                console.log('getSignInfo catch: ', exception);
                callback();
            });
        
    }

    /**
@property метод показывающий информационное сообщение, которое исчезнет через 4 секунды
@param message текст сообщения
*/
    const showInfoMessage = (message) => {
        setInfo({ isShow: true, message: message });
        setTimeout(() => {
            setInfo({ isShow: false, message: '' });
        }, "4000");
    }

    /**
   @property метод показывающий сообщение об ошибке
   @param message текст сообщения об ошибке
*/
    const showErrorMessage = (message) => {
        setError({ isError: true, message: message });
    }

    /**
@property метод очищающий сообщения об ошибке на экране пользователя
*/
    const clearErrorMessage = () => {
        setError({ isError: false, message: '' });
    }

    /**
@property метод возвращающий свойство LotId
*/
    const getIdValue = () => {
        return props.idValue;
    }

    const getIdName = () => {
        return props.idProperty;
    }

    /**
@property метод добавляющий новую запись в таблицу о новом документе. Этот метод добаляет новый объект документа в 
 свойство rowsData, которое у нас проведено в дочерний компонент Row. И при добавлении документа дочерний компонент перерисует себя.
*/
    const addTableRows = () => {

        const rowsInput = {
            DocumentId: 0,
            DocumentTitle: { Mode: 'Edit', Value: '', OldValue: '' },
            Comment: { Mode: 'Edit', Value: '', OldValue: '' },
            DocumentFile: { Mode: 'Edit', Value: null, OldValue: null },
            DisplayNumber: rowsData.length,
            DocumentFileName: "",
            IsAutoSave: false,
            IsError: false,
            IsSigned: false,
            SignStatusDescription: '',
            Signature: '',
            DataToSign: ''
        }
        setRowsData([...rowsData, rowsInput]);

        if (props.idValue == 0 || props.idValue == undefined)
        {
            setMode({ isBlock: true, message: 'Подождите, пока завершится автосохранение лота...' });
            props.onAddDocument(function (lotId) {
                setMode({ isBlock: false, message: '' });
                //console.log('lotId=', lotId);
                props.idValue = lotId;
            });
        }

    };  

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12" style={{ pointerEvents: !mode.isBlock ? "auto" : 'none', opacity: !mode.isBlock ? 1 : 0.3 }}>
                    {/* модальное окно которое блокирует всю таблицу и показывает сообщение */}
                    <div id={props.prefix + '_modalwindow'} style={{ display: !mode.isBlock ? "none" : 'block' }}>
                        <div className="message" >
                            <img src="/Content/images/loading_sm.gif" style={{ width: '20px' }} />
                            <span>{mode.message}</span>
                        </div>
                    </div>
                    {/*  сообщение уведомляющее об ошибке при совершении дейтсвия */}
                    <div className="alert alert-danger" id={props.prefix + '_alert_error'} style={{ marginBottom: '0px', display: !error.isError ? "none" : 'block' }} role="alert">
                        <span>{error.message}</span>
                    </div>
                    {/*  сообщение уведомляющее об успешном совершении дейтсвия */}
                    <div className="alert alert-success" id={props.prefix + '_alert_success'}
                        style={{
                            marginBottom: '0px',
                            display: !info.isShow ? "none" : 'block',
                            opacity: !info.isShow ? "0" : "1",
                            transition: "all .2s",
                            visibility: !info.isShow ? "hidden" : "visible"
                        }} role="alert">
                        <span>{info.message}</span>
                    </div>
                    <table className="table fl_table">
                        <thead>
                            <tr>
                                <th width="30%">Название документа</th>
                                <th width="30%">Комментарий</th>
                                <th width="12%">Файл</th>
                                <th width="12%">Подпись</th>

                                {props.isEditable &&
                                    <th width="15%"><button className="btn btn-sm btn-primary"
                                        style={{ width: '35px', padding: '7px' }}
                                        onClick={addTableRows} >+</button></th>
                                }
                            </tr>

                        </thead>
                        <tbody>

                            <TableRows signInfo={signatureInfo} rowsData={rowsData} getIdValue={getIdValue} getIdName={getIdName} clearErrorMessage={clearErrorMessage}
                                setRowsData={setRowsData} showInfoMessage={showInfoMessage} showErrorMessage={showErrorMessage} isEditable={props.isEditable} onDocSigned={props.onDocSigned} />

                        </tbody>
                    </table>

                </div>
                <div className="col-sm-4">

                </div>
            </div>
        </div>
    )

}
export default AddDeleteTableRows