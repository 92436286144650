import React, { useEffect, useState } from 'react'
import { GetTradeApplicationPromise, SaveTradeApplication, Apply, CallAway } from "../remote";
import { useDispatch, useSelector } from 'react-redux'
import { setApplication, setLoading, setLoadingFinish, setConfirm } from "../applicationSlice";
import { ITradeApplication, ApplicationStatusses } from '../types';
import { DATE_TIME_FORMAT } from "../../settings";
import moment from "moment";
import $ from "jquery";
import AddDeleteTableRows from "../../Common/Table/Table";
import { Checkbox } from "../../Common/Form";


interface IPropsType {
    lotId: Number
}

export function ApplicationDetails({ lotId }: IPropsType) {

    const [firstTime, setFirstTime] = useState(true);
    const [application, setApplication] = useState(null);

    //const isLoading = useSelector((state) => state.applicationDetails.isLoading)
    //const error = useSelector((state) => state.applicationDetails.error)
    //const confirm = useSelector((state) => state.applicationDetails.confirm)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [confirm, setConfirm] = useState(false)

    useEffect(() => {
        if (firstTime) {
            GetTradeApplicationPromise(lotId).then((result) => {
                if (result.Success) {
                    setApplication(result.UpdatedItem);
                }
            });
        }

        setFirstTime(false);
    }, []);

    const autoSave = (callback) => {
        SaveTradeApplication(application).then((result) => {
            if (result.Success) {
                setApplication(result.UpdatedItem);
                callback(result.UpdatedItem.Id);
            }
        });
    }

    const apply = () => {
        setIsLoading(true);
        setError(null)
        Apply(application).then((result) => {
            if (result.Success) {
                setApplication(result.UpdatedItem);
            }
            setIsLoading(false);
            setError(result.Error)
        });
    }

    const callaway = () => {
        setIsLoading(true);
        setError(null)
        CallAway(application).then((result) => {
            if (result.Success) {
                setApplication(result.UpdatedItem);
            }
            setIsLoading(false);
            setError(result.Error)
        });
    }

    const showHidePopup = (action) => {
        console.log(lotId);
        $('#message-details-modal' + lotId.toString()).modal(action);
    }

    useEffect(() => {
        console.log(application);
    }, [application]);

	return (
        <div className="trade-application-lot">
            {application != null &&
                <div>
                    <button onClick={(e) => { e.preventDefault(); showHidePopup('show'); }} className="btn btn-primary btn-font-14 btn-lg btn-block px-10 btn-sm-mobile">{application.Id == 0 ? "Подать заявку" : "Посмотреть заявку"}</button>

                    <div className="modal fade app-modal" id={"message-details-modal" + lotId.toString()} tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-width-1064 modal-dialog-centered" role="document">
                    <div className="modal-content">

                                <button type="button" className="modal-close-btn" data-dismiss="modal" aria-label="Close" onClick={(e) => { e.preventDefault(); showHidePopup('hide'); }}>
                            <i className="m-icon-timer"></i>
                        </button>
                        <div className="modal-body text-center">
                            <h3 className="section-title mb-3">{application.Id == 0 ? "Подать заявку" : "Заявка номер " + application.Id.toString()}</h3>
                            {application.Id != 0 &&
                                <p className="paragraph size-14 size-sm-12 opacity-1 mb-3 pb-3">
                                            Добавлено: {moment(application.CreateDate).format(DATE_TIME_FORMAT)}
                                            <br />
                                            Статус: {application.StatusName}
                                        </p>
                            }

                                    <AddDeleteTableRows
                                        idValue={application.Id}
                                        idProperty="TradeApplicationId"
                                        data={application.Documents}
                                        prefix="fl"
                                        onAddDocument={(callback) => {
                                            autoSave(callback);
                                        }}
                                        isEditable={(application.Id == 0 || application.StatusId == ApplicationStatusses.New || application.StatusId == ApplicationStatusses.Declined || application.StatusId == ApplicationStatusses.Cancelled)}
                                        onDocSigned={(id, error) => { showHidePopup('show'); } }
                                    />

                                    {error && <p className={"always-block invalid-feedback mb-0 pt-2"}>{error}</p>}

                                    {(application.StatusId == ApplicationStatusses.New || application.StatusId == ApplicationStatusses.Declined || application.StatusId == ApplicationStatusses.Cancelled) &&
                                        <React.Fragment>
                                            <Checkbox additionalClass={"mb-2"} label={"Я согласен с условиями"} disabled={false} name={"ConfirmAgreement"} value={confirm}
                                                                            onChange={(e) => {
                                                                                setConfirm(e.target.checked);
                                                                            }}
                                                                        />
                                            {confirm &&
                                                <button className="btn btn-primary btn-sm-mobile btn-font-14 btn-lg px-2" onClick={(e) => { apply(); }}>
                                                    {isLoading ? (
                                                        <img
                                                            src="/Content/images/loading_sm.gif"
                                                            style={{ width: "20px" }}
                                                        />
                                                    ) : "Подать заявку"}
                                                </button>}
                                            </React.Fragment>
                                    }

                                    {(application.StatusId == ApplicationStatusses.WaitingForConfirmation) &&
                                        <button className="btn btn-primary btn-sm-mobile btn-font-14 btn-lg px-2" onClick={(e) => { callaway(); }}>
                                            {isLoading ? (
                                                <img
                                                    src="/Content/images/loading_sm.gif"
                                                    style={{ width: "20px" }}
                                                />
                                            ) : "Отозвать Заявку" }
                                        </button>
                                    }
                                    
                        </div>

                    </div>
                </div>
                    </div></div>
            }
		</div>
	);
}