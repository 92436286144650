import React, {useState, useEffect, ChangeEventHandler, FocusEventHandler, useRef} from "react";
import { ErrorMessage } from "formik";

interface IPropsType {
    label: string,
    name: string,
    value: boolean,
    removeValidationMsg?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement>,
    onBlur?: FocusEventHandler<Element>,
    tooltipText?: string,
    subcaption?: string,
    additionalClass?: string,
    children?: React.ReactNode;
    disabled: boolean
}

function checkbox(props: IPropsType) {
    const stdClass = 'custom-control custom-checkbox app-custom-check ';
    
    return (
        <div className={props.additionalClass ? stdClass + props.additionalClass : stdClass}>
            <input  className="custom-control-input" id={props.name} type="checkbox" onChange={props.onChange} onBlur={props.onBlur}  checked={props.value} disabled={props.disabled} />
            <label className="text-nowrap custom-control-label font-size-16" htmlFor={props.name}>{props.label}
            </label>
            {props.children}
         </div>
        //<div className="form-group">
        //    <label {...(props.tooltipText ? { "data-toggle": "tooltip", title: props.tooltipText } : {})}>
        //        <input name={props.name} type="checkbox" onChange={props.onChange} onBlur={props.onBlur} className="second_group"
        //            checked={props.value}  disabled={props.disabled} /> {props.label}
        //    </label>

        //    {!props.removeValidationMsg && <ErrorMessage name={props.name} component="span" className="field-validation-error" />}
        //    {props.subcaption && <div dangerouslySetInnerHTML={{ __html: props.subcaption }}></div>}
        //</div>
    );

}

export default checkbox;