import React, {useState, useEffect, useRef} from "react";
import PicturesCrop from "../PictureLoader/PicturesCrop";
import Dropzone from 'react-dropzone'
import { useSignal, useSlot } from 'react-signal-slot';
import { string } from "yup";

interface Picture {
    Id: number,
    Path: string,
    DisplayOrder: number,
    IsDelete: boolean,
    IsUpdate: boolean,
    File?: File
}

interface IPropsType {
    PicturesList?: Array<Picture>
}

function ListPicturesLoader(props: IPropsType) {

    const [isOpen, setIsOpen] = useState(false);
    const [pictures, setPictures] = useState<Array<Picture>>([]);
    const [imageUrl, setImageUrl] = useState('');
    const [canvas, setCanvas] = useState(null);
    const [newPicture, setNewPicture] = useState(null);
    const sendPicturesList = useSignal();
    const [error, setError] = useState(null);

    useEffect(() => {
        const canvas = document.createElement("canvas");
        setCanvas(canvas);
    }, [])

    // Устанавливаем картинки из пропсов, если они приходят
    useEffect(() => {
        if (props.PicturesList) {
            setPictures(props.PicturesList);
        }
    }, [props.PicturesList])

    // Создание картинки Base64 для того чтобы сразу показать ее на странице
    // и заполнение объекта картинки
    const handlePictureCrop = (picture: File) => {
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = URL.createObjectURL(picture);

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);
            const newImageUrl = canvas.toDataURL(picture.type);

            const getIndex = pictures.length;

            setPictures(prev => [
                ...prev,
                {
                    Id: -1,
                    Path: newImageUrl,
                    DisplayOrder: getIndex,
                    IsDelete: false,
                    IsUpdate: false,
                    File: picture
                }
            ]);

        };
    };

    // Валидация( > 2МБ ), создание объекта файла загруженной картинки и открытие попапа для редактирования 
    const handlePictureDropZone = (picture) => {
        if (picture.size > 2097152) {
            setError('Размер файла не должен прeвышать 2 МБ')
        } else {
            setNewPicture(new File([picture[0]], picture[0].name, { type: picture[0].type }));
            setIsOpen(true);
        }
    }

    // Обработчик смены главной картинки, меняет местами в массиве, а так же Id, DisplayOrder и IsUpdate у каждой
    const changeGeneral = (fromIndex, toIndex) => {
        setPictures(prev => {
            const newArray = [...prev];
            const [movedItem] = newArray.splice(fromIndex, 1);
            newArray[0].DisplayOrder = movedItem.DisplayOrder;
            newArray[0].IsUpdate = true;
            movedItem.DisplayOrder = 0;
            movedItem.IsUpdate = true;
            newArray.splice(toIndex, 0, movedItem);
            return newArray;
        });
    };

    // Удаление изображения
    const deletePicture = (index) => {
        setPictures(prev => {
            const newArray = [...prev];
            newArray[index].IsDelete = true;
            return newArray;
        })
    }

    // При обновлении массива картинок, переписывает DisplayOrder равный индексу в массиве для каждого объекта 
    // и отправляет в родительский компонент через сигнал
    useEffect(() => {
        const newArray = [...pictures];
        sendPicturesList('getPicturesList',
            newArray.map((item, index) => ({
                ...item,
                DisplayOrder: index
            }))
        );
    }, [pictures]);
    
    return (
        <>
            <div className="listPicturesLoader">
                <div className="defaultDropZone">
                    <h5 className="smallPurpleTitle">Фотографии</h5>
                    <Dropzone
                        onDrop={handlePictureDropZone}
                        maxSize={2097152}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="defaultDropZoneButton"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <img src="../../main-images/dropZoneIcon.svg"></img>
                            </div>
                        )}
                    </Dropzone>

                    <p className="dropZoneRules">Максимальный размер одного файла не более 2 Мб
                        Рекомендуем загружать только горизонтальные фото </p>
                    {error &&
                        <p className="errorText">{error}</p>   
                    }
                </div>

                <div className="picturesList">
                    {pictures.map((picture, index) => (
                        !picture.IsDelete &&
                            <div className={index == 0 ? 'pictureItem general' : 'pictureItem'} key={index}>
                                <img src={picture.Path} alt={`Picture ${index}`} />
                                <div className="actions">
                                    {index != 0 &&
                                        <span onClick={() => { changeGeneral(index, 0) }} className="makeGeneral">Сделать главной</span>
                                    }
                                    {index == 0 &&
                                        <p>Главная</p>
                                    }
                                    <span></span>
                                    <button onClick={() => { deletePicture(index) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                    ))}
                </div>
            </div>

            {isOpen &&
                <div className="defaultPopupWrapper popupLoadPictures">
                    <div className="defaultPopup">
                        <button className="closePopupButton" onClick={() => { setIsOpen(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M0.424745 0.15132C0.106868 0.347042 -0.0479959 0.714022 0.0253604 1.11362C0.0742647 1.38274 0.441047 1.79049 2.86181 4.21255L5.6412 7.00975L2.86181 9.79878C0.408444 12.2616 0.0742647 12.6368 0.0172097 12.914C-0.105051 13.5746 0.441046 14.1047 1.11756 13.9823C1.37023 13.9334 1.83482 13.5093 4.20667 11.1444L6.99422 8.36349L9.78991 11.1444C12.1536 13.5093 12.6182 13.9334 12.8709 13.9823C13.5474 14.1047 14.0935 13.5746 13.9712 12.914C13.9142 12.6368 13.58 12.2616 11.1266 9.79878L8.34723 7.00975L11.1266 4.21255C14.1668 1.16255 14.1587 1.17886 13.8897 0.53461C13.8001 0.322577 13.6778 0.200251 13.4659 0.110544C12.822 -0.158573 12.8383 -0.166728 9.80621 2.85881C8.28203 4.37565 7.01052 5.62338 6.97791 5.62338C6.95346 5.62338 5.69825 4.39196 4.19037 2.89143C2.67434 1.38274 1.35393 0.1187 1.23982 0.0779247C0.930089 -0.0444021 0.71002 -0.0199366 0.424745 0.15132Z" fill="#690097" />
                            </svg>
                        </button>
                        <div className="popupBody">
                            <PicturesCrop img={newPicture} uploadImage={handlePictureCrop} setCropActive={(bool) => { setIsOpen(bool); setNewPicture(null) }} />
                        </div>
                    </div>
                </div>   
            }
        </>
    );

}

export default ListPicturesLoader;